import { lazy } from 'react'

import {
  AdminChildrenPath,
  AdminCrudPath,
  AdminExBoardChildrenPath,
} from '@/constants/constants'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(() => import('@/pages/AdminPage/AdminExBoardsPage/index'))
// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () => import('@/pages/AdminPage/AdminExBoardsPage/AdminExBoardsIndexPage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(
  () => import('@/pages/AdminPage/AdminExBoardsPage/AdminExBoardsCreatePage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(
  () => import('@/pages/AdminPage/AdminExBoardsPage/AdminExBoardsEditPage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Archive = lazy(
  () => import('@/pages/AdminPage/AdminExBoardsPage/AdminExBoardsArchivePage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Grade = lazy(() => import('@/pages/AdminPage/AdminExBoardsPage/Grade'))
// eslint-disable-next-line react-refresh/only-export-components
const Objective = lazy(
  () => import('@/pages/AdminPage/AdminExBoardsPage/Objective'),
)

export default {
  path: AdminChildrenPath.EX_BOARD,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:id`,
      element: <Edit />,
    },
    {
      path: `:id/${AdminExBoardChildrenPath.GRADING}`,
      element: <Grade />,
    },
    {
      path: `:id/${AdminExBoardChildrenPath.OBJECTIVE}`,
      element: <Objective />,
    },
    {
      path: 'archives',
      element: <Archive />,
    },
  ],
}
