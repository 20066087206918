import {
  ChatError,
  ChatMessage,
  ChatRoom as IvsChatRoom,
  ChatRoomListenerMap,
  DeleteMessageEvent,
  DeleteMessageRequest,
  DisconnectReason,
  DisconnectUserRequest,
  SendMessageRequest,
} from 'amazon-ivs-chat-messaging'
import { ConnectionState } from 'amazon-ivs-web-broadcast'

import { noop } from '@/helpers/media.ts'
import {
  ChatEventData,
  ChatEventName,
  ChatParticipantInfo,
  ChatRoomOptions,
} from '@/providers/types.ts'
import { getSgService } from '@/services/sg.ts'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isChatError(error: any): error is ChatError {
  return error && error.errorCode
}

const defaultOptions: Required<ChatRoomOptions> = {
  logLevel: 'error',
  maxReconnectAttempts: 10,
  onChatError: noop,
  onChatSuccess: noop,
  onTypingStateChange: noop,
}

class ChatRoom {
  private readonly currentUser: User
  private readonly room: IvsChatRoom
  private readonly chatArn: string
  private readonly rawRoomId: string
  private readonly events = new Map<
    ChatEventName,
    ChatEventData<ChatEventName>
  >()

  private participantInfo: ChatParticipantInfo = {}

  private readonly participants: Set<string>

  private options: Required<ChatRoomOptions>

  private eventMap: Partial<ChatRoomListenerMap> = {}
  private readonly wsSendMessage: (msg: SgWsMessage, keep?: boolean) => void
  private readonly updateState: <T>(roomId: string, chat: Partial<T>) => void
  private readonly addMessage: (roomId: string, msg: ChatMessage) => void
  private readonly removeMessage: (roomId: string, msgId: string) => void

  constructor({
    roomId,
    participants,
    chatArn,
    eventMap = {},
    options = defaultOptions,
    wsSendMessage,
    updateState,
    currentUser,
    addMessage,
    removeMessage,
  }: {
    roomId: string
    participants: string[]
    chatArn: string
    eventMap: Partial<ChatRoomListenerMap>
    options?: ChatRoomOptions
    wsSendMessage: (msg: SgWsMessage, keep?: boolean) => void
    updateState: <T>(roomId: string, chat: Partial<T>) => void
    addMessage: (roomId: string, msg: ChatMessage) => void
    removeMessage: (roomId: string, msgId: string) => void
    currentUser: User
  }) {
    this.options = { ...defaultOptions, ...options }
    this.chatArn = chatArn
    this.rawRoomId = roomId
    this.wsSendMessage = wsSendMessage
    this.currentUser = currentUser
    this.eventMap = eventMap
    this.participants = new Set(participants)

    // Create chat room
    this.room = new IvsChatRoom({
      id: roomId,
      regionOrUrl: import.meta.env.VITE_IVS_REGION,
      maxReconnectAttempts: this.options.maxReconnectAttempts,
      tokenProvider: () => getSgService().createChatToken(roomId),
    })
    this.room.logLevel = this.options.logLevel
    this.updateState = updateState
    this.addMessage = addMessage
    this.removeMessage = removeMessage

    Object.entries({
      connect: this.onConnect.bind(this),
      connecting: this.onConnecting.bind(this),
      disconnect: this.onDisconnect.bind(this),
      message: this.onMessage.bind(this),
      messageDelete: this.onMessageDelete.bind(this),
    }).forEach(([eventName, listener]) => {
      const removeListener = this.room.addListener(
        eventName as ChatEventName,
        listener,
      )
      this.events.set(eventName as ChatEventName, {
        listener,
        removeListener,
      })
    })

    // Register event listeners
    /*Object.entries(eventMap).forEach(([eventName, listener]) => {
      const removeListener = this.room.addListener(
        eventName as ChatEventName,
        listener,
      )
      this.events.set(eventName as ChatEventName, {
        listener,
        removeListener,
      })
    })*/

    this.getState = this.getState.bind(this)
    this.connect = this.connect.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.deleteMessage = this.deleteMessage.bind(this)
    this.disconnectUser = this.disconnectUser.bind(this)
    this.removeAllListeners = this.removeAllListeners.bind(this)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private log(...msg: any) {
    console.log(`>>> CHAT ROOM ${this.roomId()}`, ...msg)
  }
  private onConnect() {
    this.wsSendMessage({
      msg: 'chat',
      action: 'connect',
      userId: this.currentUser.id,
      roomId: this.roomId(),
    })
    this.log('CONNECTED')
    /*
    successToast(`connected to chat room`, {
      duration: 100,
    })
     */
    this.updateState(this.roomId(), {
      connectionState: ConnectionState.CONNECTED,
      connectionError: '',
    })
    this.eventMap.connect?.()
  }

  private onConnecting() {
    this.log('CONNECTING')
    this.updateState(this.roomId(), {
      connectionError: undefined,
      chatError: undefined,
      connectionState: ConnectionState.CONNECTING,
    })
    this.eventMap.connecting?.()
  }
  private onDisconnect(reason: DisconnectReason) {
    this.log('DISCONNECTED')
    this.updateState(this.roomId(), {
      connectionState: ConnectionState.DISCONNECTED,
    })

    if (reason === 'clientDisconnect') {
      // Clean disconnect (by client)
      //messagesActions.clear()
    } else if (reason === 'socketError' || reason === 'fetchTokenError') {
      // Dirty disconnect
      this.updateState(this.roomId(), {
        connectionError: reason,
      })
    }

    this.eventMap.disconnect?.(reason)
  }
  private onMessageDelete(event: DeleteMessageEvent) {
    this.removeMessage(this.roomId(), event.messageId)
    this.eventMap.messageDelete?.(event)
  }

  private onMessage(msg: ChatMessage) {
    this.wsSendMessage({
      msg: 'chat',
      action: 'new-chat',
      message: msg,
      roomId: this.roomId(),
      userId: this.currentUser.id,
    })
    this.addMessage(this.roomId(), msg)
    this.eventMap.message?.(msg)
  }

  public addParticipants(next: string[]) {
    for (const p of next) {
      this.participants.add(p)
    }
  }
  public removeParticipants(next: string[]) {
    for (const p of next) {
      this.participants.delete(p)
    }
  }
  public getParticipants() {
    return Array.from(this.participants)
  }
  public getParticipantSize() {
    // the other one is system
    return this.participants.size - 1
  }
  public set chatParticipantInfo(participantInfo: { id?: string }) {
    this.participantInfo = { ...this.participantInfo, ...participantInfo }
  }

  public getState() {
    return this.room.state
  }

  public connect() {
    this.room.connect()
  }

  public disconnect() {
    this.room.disconnect()
  }

  public removeAllListeners() {
    this.events.forEach(({ removeListener }) => removeListener())
  }

  public async startTyping(userId: string) {
    return this.wsSendMessage({
      msg: 'chat',
      action: 'typing',
      roomId: this.room.id,
      userId,
    })
    //return this.options.onTypingStateChange?.(true, userId, this.room.id)
  }

  public async stopTyping(userId: string) {
    return this.wsSendMessage({
      msg: 'chat',
      action: 'stop-typing',
      roomId: this.room.id,
      userId,
    })
    //return this.options.onTypingStateChange?.(false, userId, this.room.id)
  }

  public async sendMessage(
    message: string,
    extraAttrs?: Record<string, unknown>,
  ) {
    const { participantInfo } = this
    const attributes = {
      participantInfo: JSON.stringify(participantInfo),
      ...extraAttrs,
    }

    try {
      console.log('sending chat message', message, attributes)
      const request = new SendMessageRequest(message, attributes)
      const response = await this.room.sendMessage(request)
      this.updateState(this.roomId(), {
        chatError: undefined,
      })

      return response
    } catch (error) {
      console.error(error)

      if (isChatError(error)) {
        this.updateState(this.roomId(), {
          chatError: error,
        })
      }
    }
  }

  public async deleteMessage(messageId: string, reason?: string) {
    const {
      options: { onChatSuccess, onChatError },
    } = this

    try {
      const request = new DeleteMessageRequest(messageId, reason)
      const response = await this.room.deleteMessage(request)
      onChatSuccess()
      return response
    } catch (error) {
      if (isChatError(error)) {
        onChatError(error)
      }
    }
  }

  public async disconnectUser(userId: string, reason?: string) {
    const {
      options: { onChatSuccess, onChatError },
    } = this

    try {
      const request = new DisconnectUserRequest(userId, reason)
      const response = await this.room.disconnectUser(request)
      onChatSuccess()

      return response
    } catch (error) {
      if (isChatError(error)) {
        onChatError(error)
      }
    }
  }

  public roomId() {
    return this.rawRoomId
  }
  public arn() {
    return this.chatArn
  }
}

export default ChatRoom
