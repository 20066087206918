import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { nanoid, Sym } from '@edclass/fe-ui'
import { IconButton, Textarea } from '@material-tailwind/react'
import clsx from 'clsx'
import {
  Context,
  Fragment,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import EdColorPickerDropdown from '@/components/ColorPicker/EdColorPickerDropdown.tsx'
import InputWithHelp from '@/components/Form/InputWithHelp.tsx'
import IconPicker from '@/components/IconPicker'
import NumInput from '@/components/Input/NumInput.tsx'
import MultiSelect from '@/components/MultiSelect'
import { useRepeater } from '@/components/Repeater/hooks.ts'
import {
  BaseRepeaterValue,
  RepeaterContext,
  RepeaterContextValue,
  RepeaterFieldProps,
} from '@/components/Repeater/RepeaterContext.tsx'
import SelectWithSearch from '@/components/SelectWithSearch'
import SortableItem from '@/components/SortableItem.tsx'
import { useMount } from '@/hooks/useMount.ts'
import useSortableProps from '@/hooks/useSortableProps.ts'

export type RepeaterProps<V extends BaseRepeaterValue> = Omit<
  RepeaterContextValue<V>,
  'updateValue' | 'removeValue' | 'addValue' | 'handleEnter'
>

function RepeaterField<V extends BaseRepeaterValue>({
  type,
  field,
  props,
  parseValue,
  value,
  showCb,
  focus,
}: RepeaterFieldProps<V> & {
  value: V
  focus?: boolean
}) {
  const { updateValue, handleEnter } = useRepeater()
  const isMount = useMount()
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>(
    null,
  )

  useEffect(() => {
    if (isMount() && focus) {
      setTimeout(() => {
        const current = ref.current as HTMLDivElement
        if (current) {
          const res = current?.querySelector(
            'input, textarea',
          ) as HTMLDivElement
          if (res) {
            res.focus?.()
          }
        }
      })
    }
  }, [ref, isMount, focus])

  if (showCb && !showCb(value)) {
    return null
  }

  switch (type) {
    case 'input':
      return (
        <InputWithHelp
          ref={ref as RefObject<HTMLInputElement>}
          size="lg"
          autoFocus
          {...props}
          value={(value[field] as string) ?? ''}
          onChange={(e) => {
            const val = e.currentTarget.value
            const v = { ...value, [field]: parseValue ? parseValue(val) : val }
            updateValue(v)
          }}
          onKeyDown={handleEnter}
        />
      )
    case 'text':
      return (
        <Textarea
          ref={ref as RefObject<HTMLDivElement>}
          size="md"
          autoFocus
          {...props}
          value={(value[field] as string) ?? ''}
          onChange={(e) => {
            const val = e.currentTarget.value
            const v = { ...value, [field]: parseValue ? parseValue(val) : val }
            updateValue(v)
          }}
          onKeyDown={handleEnter}
        />
      )
    case 'number':
      return (
        <NumInput
          ref={ref as RefObject<HTMLInputElement>}
          size="lg"
          autoFocus
          {...props}
          value={(value[field] as string) ?? ''}
          onChange={(e) => {
            const val = e.currentTarget.value
            const v = { ...value, [field]: parseValue ? parseValue(val) : val }
            updateValue(v)
          }}
          onKeyDown={handleEnter}
        />
      )
    case 'icon':
      return (
        <IconPicker
          {...props}
          value={(value[field] as string) ?? ''}
          onChange={(val) => {
            const v = { ...value, [field]: parseValue ? parseValue(val) : val }
            updateValue(v)
          }}
        />
      )
    case 'color':
      return (
        <EdColorPickerDropdown
          {...props}
          value={(value[field] as string) ?? ''}
          onChange={(val) => {
            const v = { ...value, [field]: parseValue ? parseValue(val) : val }
            updateValue(v)
          }}
        />
      )
    case 'select':
      return (
        <SelectWithSearch
          ref={ref as never}
          value={props?.options?.find((opt) => {
            const p = parseValue ? parseValue(opt) : opt
            return p === value[field]
          })}
          {...(props || { options: [] })}
          onChange={(e) => {
            const v = { ...value, [field]: parseValue ? parseValue(e) : e }
            updateValue(v)
          }}
        />
      )
    case 'multiselect':
      return (
        <MultiSelect
          ref={ref as never}
          menuProps={{
            className: 'z-[10000]',
          }}
          value={props?.options?.filter((o) => {
            const p = parseValue ? parseValue(o) : o
            return (value[field] as string[])?.includes(p)
          })}
          {...(props || { options: [] })}
          onChange={(e) => {
            const v = { ...value, [field]: parseValue ? e.map(parseValue) : e }
            updateValue(v)
          }}
        />
      )
  }
}

function RepeaterFields<V extends BaseRepeaterValue>({
  id,
  fields,
  listeners,
  value,
}: {
  id: string
  fields: RepeaterFieldProps<V>[]
  listeners?: SyntheticListenerMap
  value: V
}) {
  const { removeValue } = useRepeater()
  return (
    <div className="flex-c-2">
      <div data-testid="input-repeater-fieldset" className="flex-c-2 w-full">
        {fields.map((f, i) => {
          return (
            <Fragment key={`${id}-${f.field as string}`}>
              <RepeaterField
                focus={i === 0}
                {...f}
                field={f.field}
                value={value}
              />
              {f.after?.(value, i)}
            </Fragment>
          )
        })}
      </div>
      <IconButton
        data-testid="input-repeater-delete"
        className="flex-shrink-0"
        color="red"
        onClick={() => {
          removeValue(id)
        }}
      >
        <Sym className="!text-[20px]">delete</Sym>
      </IconButton>
      <IconButton
        data-testid="input-repeater-sort"
        className="flex-shrink-0"
        color="white"
        {...listeners}
      >
        <Sym className="!text-[20px]">swap_vert</Sym>
      </IconButton>
    </div>
  )
}

export default function Repeater<V extends BaseRepeaterValue>({
  id,
  value,
  onChange,
  grid,
  fields,
  className,
  nextOnEnter,
}: RepeaterProps<V> & {
  className?: string
}) {
  const removeValue = useCallback(
    (id: string) => {
      return onChange(
        value.filter((v) => v.id !== id),
        null,
        false,
      )
    },
    [value, onChange],
  )

  const addValue = useCallback(() => {
    return onChange(
      [
        ...value,
        Object.fromEntries(
          fields
            .map((f) => {
              return [f.field, undefined]
            })
            .concat([['id', nanoid()]]),
        ) as V,
      ],
      value.length,
      true,
    )
  }, [value, fields, onChange])

  const updateValue = useCallback(
    (v: V) => {
      const copy = [...value]
      const idx = copy.findIndex((c) => c.id === v.id)
      if (idx !== -1) {
        copy[idx] = v
      }
      onChange(copy, idx, false)
    },
    [value, onChange],
  )

  const sortableProps = useSortableProps()
  const handleSort = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (over && active.id !== over?.id) {
        const activeIndex = value.findIndex(({ id }) => id === active.id)
        const overIndex = value.findIndex(({ id }) => id === over.id)
        onChange(arrayMove(value, activeIndex, overIndex), null, false)
      }
    },
    [value, onChange],
  )

  const TContext: Context<RepeaterContextValue<V> | null> = RepeaterContext

  const handleEnter = useCallback(
    (e: React.KeyboardEvent) => {
      if (nextOnEnter && e.key === 'Enter') {
        addValue()
      }
    },
    [nextOnEnter, addValue],
  )

  return (
    <TContext.Provider
      value={{
        id,
        fields,
        grid,
        onChange,
        removeValue,
        updateValue,
        addValue,
        value,
        nextOnEnter,
        handleEnter,
      }}
    >
      <div
        data-testid="input-repeater"
        className={clsx('flex flex-col gap-4', className)}
      >
        {value.length > 0 && (
          <div
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '--grid-cols': grid,
            }}
            className={clsx(
              grid && grid > 1
                ? 'input-repeater-grid grid gap-4'
                : 'flex flex-col gap-4',
            )}
          >
            <DndContext {...sortableProps} onDragEnd={handleSort}>
              <SortableContext items={value}>
                {value.map((v) => {
                  return (
                    <SortableItem key={v.id} id={v.id} sortButton>
                      {(listeners) => (
                        <RepeaterFields
                          id={v.id}
                          fields={fields}
                          value={v}
                          listeners={listeners}
                        />
                      )}
                    </SortableItem>
                  )
                })}
              </SortableContext>
            </DndContext>
          </div>
        )}
        <IconButton
          data-testid="input-repeater-add"
          onClick={() => {
            addValue()
          }}
        >
          <Sym className="!text-[20px]">add</Sym>
        </IconButton>
      </div>
    </TContext.Provider>
  )
}
