import { lazy } from 'react'

import { AdminChildrenPath, AdminCrudPath } from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(() => {
  return import('@/pages/AdminPage/AdminStaffsPage/index')
})
// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(() => {
  return import('@/pages/AdminPage/AdminStaffsPage/AdminStaffsIndexPage')
})

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(() => {
  return import('@/pages/AdminPage/AdminStaffsPage/AdminStaffsCreatePage')
})

// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(() => {
  return import('@/pages/AdminPage/AdminStaffsPage/AdminStaffsEditPage')
})

export default {
  path: AdminChildrenPath.STAFFS,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:id`,
      element: <Edit />,
    },
  ],
}
