import { createContext, ReactNode, useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import {
  SendJsonMessage,
  WebSocketLike,
} from 'react-use-websocket/dist/lib/types'
import tunnel from 'tunnel-rat'
import useLocalStorageState from 'use-local-storage-state'

import useAuthContext from '@/hooks/useAuthProvider.ts'

const sidebarTunnel = tunnel()
const headerTitleTunnel = tunnel()
const headerActionTunnel = tunnel()
const headerMenuTunnel = tunnel()

export type WsOk = {
  status: string
}
export type WsError = {
  code: number
  message: string
}

type AppContextValue = {
  title?: string
  setTitle: SetState<string | undefined>
  sidebarCollapsed: boolean
  setSidebarCollapsed: SetState<boolean>
  headerAction: typeof headerActionTunnel
  headerTitle: typeof headerTitleTunnel
  headerMenu: typeof headerMenuTunnel
  sidebar: typeof sidebarTunnel
  pageClassName?: string
  setPageClassName: SetState<string | undefined>
  lastEventMessage: unknown
  sendEventMessage: SendJsonMessage
  eventReadyState: ReadyState
  getEventWebSocket: () => WebSocketLike | null
  lastSgMessage: SgWsResponse
  sendSgMessage: (jsonMessage: SgWsMessage, keep?: boolean) => void
  getSgWebSocket: () => WebSocketLike | null
  sgReadyState: ReadyState
}

export const AppContext = createContext<AppContextValue | null>(null)
AppContext.displayName = 'AppContext'

export default function AppProvider({ children }: { children: ReactNode }) {
  const [title, setTitle] = useState<string | undefined>(undefined)
  const [sidebarCollapsed, setSidebarCollapsed] = useLocalStorageState('SB', {
    defaultValue: false,
  })
  const { user } = useAuthContext()

  const [pageClassName, setPageClassName] = useState<string>()

  const {
    lastJsonMessage: lastEventMessage,
    sendJsonMessage: sendEventMessage,
    getWebSocket: getEventWebSocket,
    readyState: eventReadyState,
  } = useWebSocket<WsError | WsOk>(
    user ? `${import.meta.env.VITE_EVENT_API_URL}/ws` : '',
    {
      shouldReconnect: () => false,
    },
  )

  const {
    lastJsonMessage: lastSgMessage,
    sendJsonMessage: sendSgMessage,
    getWebSocket: getSgWebSocket,
    readyState: sgReadyState,
  } = useWebSocket<SgWsResponse>(
    user ? `${import.meta.env.VITE_SG_API_URL}/ws` : '',
    {
      shouldReconnect: () => false,
    },
  )

  useEffect(() => {
    console.log('LAST SG MESSAGE', lastSgMessage)
  }, [lastSgMessage])

  useEffect(() => {
    console.log('LAST EVENT MESSAGE', lastEventMessage)
  }, [lastEventMessage])

  /*
  TODO: testing mouse log
  const location = useLocation()

  useEffect(() => {
    let screenSize = getScreenSize()
    const onWindowResize = () => {
      screenSize = getScreenSize(false)
    }

    window.addEventListener('resize', onWindowResize)

    let hoverTimer: number,
      hoverTime: number = 0

    document.addEventListener('mousemove', (event) => {
      clearInterval(hoverTimer)
      const x = event.clientX + window.scrollX
      const y = event.clientY + window.scrollY
      sendEventMessage({
        kind: 'mouse',
        uri: location.pathname,
        screenSize,
        coordinate: { x, y },
      })

      hoverTimer = window.setInterval(() => {
        sendEventMessage({
          kind: 'mouse',
          uri: location.pathname,
          screenSize,
          coordinate: { x, y },
        })
        hoverTime++
        if (hoverTime > 5) {
          console.log('counter reset hover')
          clearInterval(hoverTimer)
        }
      }, 1000)
    })

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [location, sendEventMessage])*/

  return (
    <AppContext.Provider
      value={{
        title,
        setTitle,
        sidebarCollapsed,
        setSidebarCollapsed,
        headerTitle: headerTitleTunnel,
        headerAction: headerActionTunnel,
        headerMenu: headerMenuTunnel,
        sidebar: sidebarTunnel,
        pageClassName,
        setPageClassName,
        lastEventMessage,
        eventReadyState,
        sendEventMessage,
        getEventWebSocket,
        lastSgMessage,
        sendSgMessage,
        getSgWebSocket,
        sgReadyState,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
