import { OffsetOptions, Placement } from '@floating-ui/react'
import { ReactNode } from 'react'

import Fab from '@/components/Buttons/Fab/index.tsx'
import { EDIcon } from '@/constants/constants.ts'

export default function FloatingChatButton({
  placement = 'bottom-start',
  offset = {
    mainAxis: 12,
  },
  dialogClassName,
  badge,
  size,
  children,
  open,
  setOpen,
}: {
  placement?: Placement
  offset?: OffsetOptions
  dialogClassName?: string
  badge?: ReactNode
  size?: 'xl' | 'lg' | 'sm' | 'xs'
  children?: ReactNode
  open?: boolean
  setOpen?: SetState<boolean>
}) {
  return (
    <Fab
      icon={EDIcon.Chat}
      dialogClassName={dialogClassName}
      badge={badge}
      offset={offset}
      placement={placement}
      color="teal"
      dialogHeight={500}
      size={size}
      open={open}
      setOpen={setOpen}
    >
      {children}
    </Fab>
  )
}
