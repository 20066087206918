// eslint-disable-next-line react-refresh/only-export-components
import { lazy } from 'react'

import { AdminCrudPath, AdminSkillChildrenPath } from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(
  () =>
    import('@/pages/AdminPage/AdminSkillsPage/AdminSkillTagsPage/index.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSkillsPage/AdminSkillTagsPage/AdminSkillTagIndexPage.tsx'
    ),
)

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSkillsPage/AdminSkillTagsPage/AdminSkillTagCreatePage.tsx'
    ),
)

// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSkillsPage/AdminSkillTagsPage/AdminSkillTagEditPage.tsx'
    ),
)

export default {
  path: AdminSkillChildrenPath.SKILL_TAG,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:skillTagId`,
      element: <Edit />,
    },
  ],
}
