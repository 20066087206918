import { addDays, format } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { formatInTimeZone, toZonedTime } from 'date-fns-tz'

import { LOCALE } from '@/constants/constants.ts'

const getOrdinalNum = (num: number) => {
  const j = num % 10
  const k = num % 100

  if (j === 1 && k !== 11) {
    return num + 'st'
  }
  if (j === 2 && k !== 12) {
    return num + 'nd'
  }
  if (j === 3 && k !== 13) {
    return num + 'rd'
  }
  return num + 'th'
}

export const getToday = (offset: number) => {
  const now = new Date()
  const futureDate = addDays(now, offset)

  const formattedDate = format(futureDate, 'EEEE')
  const dayOfMonth = format(futureDate, 'd')
  const month = format(futureDate, 'MMMM')
  const year = format(futureDate, 'yyyy')
  const ordinalDay = getOrdinalNum(+dayOfMonth)

  return `${formattedDate} the ${ordinalDay} of ${month} ${year}`
}

export const getYMDFormat = (date: Date): string => {
  const utcDate = toZonedTime(date, 'UTC')
  return format(utcDate, 'yyyy-MM-dd')
}

export function toLocaleDateTime(value: string | Date) {
  return new Date(value).toLocaleString(LOCALE, {
    dateStyle: 'medium',
    timeZone: 'Europe/London',
    timeStyle: 'medium',
  })
}

export function toLocaleDateTimePicker(value: string | Date) {
  return new Date(value).toLocaleString(LOCALE, {
    dateStyle: 'medium',
    timeStyle: 'medium',
  })
}

export function toLocaleDate(value: string | Date) {
  return new Date(value).toLocaleString(LOCALE, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'Europe/London',
  })
}

export function toLocaleTime(value: string | Date) {
  return formatInTimeZone(new Date(value), 'Europe/London', 'HH:mm a', {
    locale: enGB,
  })
}

export function toLocaleDatePicker(value: string | Date) {
  return new Date(value).toLocaleString(LOCALE, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}

export function getDateCol<T extends Record<string, unknown>>(
  size?: number,
  header?: string,
  key?: keyof T,
) {
  const k = key || 'createdAt'
  return {
    header: header || 'Created At',
    accessorFn: (col: T) => {
      if (col?.[k]) {
        if (typeof col[k] === 'string') {
          return toLocaleDateTime(col[k] as string)
        } else if (
          col[k] instanceof Date ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          typeof col[k].toLocaleDateString === 'function'
        ) {
          return toLocaleDateTime(col[k] as Date)
        }
      }
      return 'N/A'
    },
    size,
  }
}

export function getEventDateCol<T extends EventAuditObject>(
  size?: number,
  header?: string,
  key?: keyof T,
) {
  const k = key || 'createdAt'
  return {
    header: header || 'Created At',
    accessorFn: (col: EventAuditItem<T>) => {
      if (col?.object?.[k]) {
        if (typeof col.object[k] === 'string') {
          return toLocaleDateTime(col.object[k] as string)
        } else if (
          col.object[k] instanceof Date ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          typeof col.event[k].toLocaleDateString === 'function'
        ) {
          return toLocaleDateTime(col.object[k] as Date)
        }
      }
      return 'N/A'
    },
    size,
  }
}

export function getLogTimeCol<T extends EventAuditItem<never>>(
  size?: number,
  header?: string,
) {
  return {
    header: header || 'Log Time',
    accessorFn: (col: T) => {
      if (col?.event.logTime) {
        return toLocaleDateTime(col.event.logTime)
      }
      return 'N/A'
    },
    size,
  }
}
