import { Sym } from '@edclass/fe-ui'
import { IconButton } from '@material-tailwind/react'
import WavesurferPlayer from '@wavesurfer/react'
import { useState } from 'react'
import WaveSurfer from 'wavesurfer.js'

import { EDIcon } from '@/constants/constants.ts'

export default function ChatVoicePlayer({
  src,
  onRemove,
}: {
  src: string
  onRemove?: () => void
}) {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null)
  const [isPlay, setIsPlay] = useState(false)

  return (
    <div className="flex-c-2 w-full p-2 bg-gray-300 rounded-md mb-2">
      <IconButton
        size="sm"
        color="teal"
        className="flex-shrink-0"
        onClick={() => {
          wavesurfer?.playPause()
        }}
      >
        <Sym>{isPlay ? 'pause' : 'play_arrow'}</Sym>
      </IconButton>
      {onRemove && (
        <IconButton
          size="sm"
          color="red"
          className="flex-shrink-0"
          onClick={() => {
            onRemove()
          }}
        >
          <Sym className="!text-[20px]">{EDIcon.Delete}</Sym>
        </IconButton>
      )}
      <div className="w-full">
        <WavesurferPlayer
          url={src}
          height={40}
          waveColor="#009FE3"
          progressColor="#E15A24"
          //waveColor="rgb(200, 0, 200)"
          //progressColor="rgb(100, 0, 100)"
          onReady={(ws) => {
            setWavesurfer(ws)
            setIsPlay(false)
          }}
          onPlay={() => setIsPlay(true)}
          onPause={() => setIsPlay(false)}
        />
      </div>
    </div>
  )
}
