import { DraggableDialog, Sym } from '@edclass/fe-ui'
import { DialogBody, DialogHeader, Typography } from '@material-tailwind/react'
import clsx from 'clsx'
import { type JSX, useCallback, useMemo, useState } from 'react'

export type SetModal = (
  title: string | null,
  body?: (onClose: () => void) => JSX.Element,
) => void

export default function useModal(props?: {
  className?: string
  fullScreen?: true
  backArrow?: boolean
  withClose?: true
  noWrapBody?: boolean
}): [JSX.Element | null, SetModal, SetState<string | null>] {
  const [modalContent, setModalContent] = useState<null | {
    content: JSX.Element
    title: string
  }>(null)

  const [modalClassName, setModalClassName] = useState<null | string>(null)

  const onClose = useCallback(() => {
    setModalContent(null)
    setModalClassName(null)
  }, [])

  const modal = useMemo(() => {
    if (modalContent === null) {
      return null
    }
    const { title, content } = modalContent
    return (
      <DraggableDialog
        className={clsx(
          'bg-ed-bg',
          props?.className,
          props?.fullScreen && 'ed-modal-full',
          modalClassName,
        )}
        dismiss={{
          ancestorScroll: true,
        }}
        backdrop
        open={true}
        handler={onClose}
      >
        <DialogHeader>
          <div className="flex items-center gap-4 w-full">
            {props?.backArrow && (
              <button
                className="flex items-center justify-center text-[20px] text-white"
                onClick={onClose}
              >
                <Sym>arrow_back</Sym>
              </button>
            )}
            <Typography variant="h6" className="w-full" color="white">
              {title}
            </Typography>
            {props?.withClose && (
              <button
                onClick={onClose}
                className="text-white/50 hover:text-white grid items-center"
              >
                <Sym>close</Sym>
              </button>
            )}
          </div>
        </DialogHeader>
        {!props?.noWrapBody ? (
          <DialogBody className="">{content}</DialogBody>
        ) : (
          content
        )}
      </DraggableDialog>
    )
  }, [onClose, modalContent, props, modalClassName])

  const showModal = useCallback(
    (
      title: string | null,
      getContent?: (onClose: () => void) => JSX.Element,
    ) => {
      if (title === null || !getContent) {
        setModalContent(null)
        return
      }

      setModalContent({
        content: getContent(onClose),
        title,
      })
    },
    [onClose],
  )

  return [modal, showModal, setModalClassName]
}
