import { useCallback } from 'react'

import { BASE_PATH, SIGN_IN_PATH } from '@/constants/constants.ts'
import { errorToast } from '@/helpers/toast.tsx'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import useLoggedWsNavigate from '@/hooks/useLoggedWsNavigate.ts'

export default function useLogout(cleanup?: () => void) {
  const navigate = useLoggedWsNavigate()
  const { logout } = useAuthContext()

  return useCallback(async () => {
    try {
      navigate(`${BASE_PATH}/${SIGN_IN_PATH}`)
      await logout(cleanup)
    } catch (e) {
      return errorToast(e)
    }
  }, [navigate, cleanup, logout])
}
