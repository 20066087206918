import { Sym } from '@edclass/fe-ui'
import { Spinner } from '@material-tailwind/react'
import { ChatError, ChatMessage } from 'amazon-ivs-chat-messaging'
import { ConnectionState } from 'amazon-ivs-web-broadcast'
import clsx from 'clsx'
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import Avatar from '@/components/Avatar'
import ChatPanel from '@/components/Chat/ChatPanel.tsx'
import Search from '@/components/Search'
import { getUserDisplayName } from '@/helpers/user.ts'
import useOnlineEDTeachStudents from '@/hooks/query/useOnlineEDTeachStudents.ts'
import useAppContext from '@/hooks/useAppProvider.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import useContextOrThrow from '@/hooks/useContextOrThrow.ts'
import useSearch from '@/hooks/useSearch.ts'
import ChatRoom from '@/providers/ChatRoomProvider/ChatRoom.ts'
import { useCreateOrJoinChatRoomCb } from '@/providers/ChatRoomProvider/useCreateOrJoinChatRoom.ts'
import SearchProvider from '@/providers/SearchProvider.tsx'
import { getSgService } from '@/services/sg.ts'

type ChatDialogChat = {
  connectionState: ConnectionState
  connectionError?: string
  chatError?: ChatError
  curBackToken?: string
  prevBackToken?: string
  messages: Map<string, ChatMessage>
  owner: User
  room: ChatRoom
}

type ChatDialogContextValue = {
  owner?: string
  //rooms: ReadonlyMap<string, User>
  //roomsMutator: MapMutators<string, User>
  activeRoom?: string
  setActiveRoom: (room: string) => void
  open: boolean
  setOpen: SetState<boolean>
  stateMap: Record<string, ChatDialogChat>
  setStateMap: SetState<Record<string, ChatDialogChat>>
  updateMap: (roomId: string, context: Partial<ChatDialogChat>) => void
  addMessage: (roomId: string, messages: ChatMessage) => void
  removeMessage: (roomId: string, msgId: string) => void
  //refMap: Record<string, ChatRoom>
  //setRefMap: SetState<Record<string, ChatRoom>>
  typingUsers: Record<string, Record<string, User | null>>
  setTypingUsers: SetState<Record<string, Record<string, User | null>>>
  loadPreviousMessages: (
    roomId: string,
    backToken?: string,
    counter?: number,
    cb?: () => void,
  ) => void
  isLoading: (roomId: string) => boolean
  initialLoadRef: MutableRefObject<Record<string, string>>
}

const ChatDialogContext = createContext<ChatDialogContextValue | null>(null)
ChatDialogContext.displayName = 'ChatDialogContext'

function ChatNavItem({
  active,
  user,
  onClick,
  children,
  className,
}: {
  active?: boolean
  user?: User
  onClick?: () => void
  children?: ReactNode
  className?: string
}) {
  return (
    <button
      className={clsx(
        'w-full cursor-pointer relative',
        'transition-colors cc p-2',
        active && 'bg-teal-900 before:absolute before:right-0',
        active &&
          'before:border-y-[0.4rem] before:border-r-[0.4rem] before:border-r-white before:border-y-transparent',
        className,
      )}
      role="menuitem"
      title={getUserDisplayName(user)}
      onClick={onClick}
    >
      {children ? (
        children
      ) : (
        <Avatar
          src={`${import.meta.env.VITE_EVENT_API_URL}/api/data/avatar/by-id/${user?.id}`}
        />
      )}
    </button>
  )
}

function ChatNav() {
  const { activeRoom, setActiveRoom, stateMap } = useChatDialog()

  useEffect(() => {
    console.log('CHAT NAV', stateMap)
  }, [stateMap])

  return (
    <div
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '--width': '50px',
      }}
      className={clsx(
        'h-full bg-teal-500 w-[var(--width)] flex-shrink-0',
        'rounded-tl-[inherit] rounded-bl-[inherit]',
        'text-white flex flex-col items-center',
        'relative',
        'overflow-hidden',
        //'overflow-y-auto overflow-x-hidden no-scroll',
      )}
    >
      <div
        className={clsx(
          'h-[calc(100%-var(--width))] w-full overflow-y-auto overflow-x-hidden no-scroll',
        )}
      >
        {Object.entries(stateMap)
          .filter(([, s]) => Boolean(s.room) && Boolean(s.owner))
          .map(([k, v]) => {
            return (
              <ChatNavItem
                key={k}
                active={activeRoom === k}
                onClick={() => setActiveRoom(k)}
                user={v.owner}
              />
            )
          })}
      </div>
      <div className="h-[var(--width)] cc w-full absolute bottom-0">
        <ChatNavItem
          className="h-full"
          active={activeRoom === 'search'}
          onClick={() => {
            setActiveRoom('search')
          }}
        >
          <Sym>search</Sym>
        </ChatNavItem>
      </div>
    </div>
  )
}

function ChatSearchPanel() {
  const searchCtx = useSearch()

  const { setParams, data, isPending } = useOnlineEDTeachStudents({
    search: searchCtx.search,
  })

  useEffect(() => {
    setParams((prev) => {
      return {
        ...prev,
        search: searchCtx.debouncedSearch,
      }
    })
  }, [searchCtx.debouncedSearch, setParams])

  return (
    <div className="w-full">
      <div className="text-ed-text w-full">
        {/*
          <Input
          label="Search"
          value={value}
          onChange={(e) => {
            setValue(e.currentTarget.value)
          }}
          className="!text-ed-text w-full"
        />
           */}
        <Search
          plain
          ctx={searchCtx}
          btnClassName="absolute right-2 text-gray-500 hover:text-red-500"
          className="border-0 rounded-tr-lg bg-transparent border-b border-b-gray-300 focus-visible:outline-none py-3 ps-4 pe-8 !text-ed-text w-full"
        />
      </div>
      <div>
        {isPending ? (
          <div className="cc p-8">
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {(data?.data || []).map((d) => {
              return (
                <div
                  className="flex-c-2 px-3 py-3 odd:bg-teal-100/40"
                  key={d.student.id}
                >
                  <Avatar size={24} user={d.student} />
                  <div>{getUserDisplayName(d.student)}</div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export function ChatDialogPanel({ className }: { className?: string }) {
  const { activeRoom } = useChatDialog()
  return (
    <div
      className={clsx(
        'rounded-md bg-gray-100 text-ed-text h-full w-full flex',
        className,
      )}
    >
      <ChatNav />
      {activeRoom === 'search' ? (
        <SearchProvider>
          <ChatSearchPanel />
        </SearchProvider>
      ) : (
        <ChatPanel />
      )}
    </div>
  )
}

function ChatDialogAuto() {
  const { lastSgMessage, sendSgMessage } = useAppContext()
  const { user: currentUser } = useAuthContext()
  const {
    addMessage,
    removeMessage,
    stateMap,
    initialLoadRef,
    loadPreviousMessages,
    setOpen,
    setActiveRoom,
    updateMap,
  } = useChatDialog()
  const [createChatRoom] = useCreateOrJoinChatRoomCb()

  useEffect(() => {
    const msg = lastSgMessage
    //const roomId = msg?.room?._id?.$oid

    if (msg && msg.msg === 'chat' && msg.action === 'new-chat') {
      console.log('new-chat is coming')
      const roomId = msg.room?._id?.$oid

      if (!stateMap[roomId]?.room) {
        const chatRoom = createChatRoom(
          msg.room._id,
          msg.room.chatRoomArn || '',
          [],
          (roomId) => {
            loadPreviousMessages(roomId, undefined, 0, () => {
              initialLoadRef.current = {
                ...initialLoadRef.current,
                [roomId]: roomId,
              }
            })
          },
        )

        chatRoom.connect()
        const chatMsg = msg.message as ChatMessage
        updateMap(roomId, {
          connectionError: undefined,
          chatError: undefined,
          messages: new Map([[chatMsg.id, chatMsg]]),
          connectionState: ConnectionState.DISCONNECTED,
          room: chatRoom,
          owner: msg.room.owner as unknown as User,
        })

        setActiveRoom(roomId)
        setOpen(true)
      }
    }
  }, [
    setOpen,
    setActiveRoom,
    stateMap,
    loadPreviousMessages,
    initialLoadRef,
    currentUser,
    sendSgMessage,
    addMessage,
    removeMessage,
    updateMap,
    lastSgMessage,
    createChatRoom,
  ])

  return null
}

export function ChatDialog({
  children,
  owner,
  initialRooms,
  initialActiveRoom,
}: {
  children: ReactNode
  initialActiveRoom?: string
  initialRooms?: Record<string, User>
} & Pick<ChatDialogContextValue, 'owner'>) {
  const { lastSgMessage } = useAppContext()
  const [open, setOpen] = useState(false)
  const initialLoadRef = useRef<Record<string, string>>({})
  const [activeRoom, setActiveRoom] = useState<string>(initialActiveRoom || '')
  const [loadings, setLoadings] = useState<Record<string, boolean>>({})
  const [stateMap, setStateMap] = useState<Record<string, ChatDialogChat>>(
    initialRooms
      ? Object.fromEntries(
          Object.entries(initialRooms).map(([room, owner]) => {
            return [
              room,
              {
                room: undefined as never,
                owner,
                chatError: undefined,
                messages: new Map(),
                curBackToken: undefined,
                prevBackToken: undefined,
                connectionState: ConnectionState.NONE,
                connectionError: undefined,
              } as ChatDialogChat,
            ]
          }) || [],
        )
      : {},
  )

  const setLoading = useCallback((roomId: string, val: boolean) => {
    setLoadings((prev) => {
      return {
        ...prev,
        [roomId]: val,
      }
    })
  }, [])

  const isLoading = useCallback(
    (roomId: string) => {
      return Boolean(loadings[roomId])
    },
    [loadings],
  )

  const updateMap = useCallback(
    (roomId: string, context: Partial<ChatDialogChat>) => {
      setStateMap((prev) => {
        if (!prev[roomId]) {
          return {
            ...prev,
            [roomId]: context as unknown as ChatDialogChat,
          }
        } else {
          return {
            ...prev,
            [roomId]: {
              ...prev[roomId],
              ...context,
            },
          }
        }
      })
    },
    [],
  )

  /*
  const removeMap = useCallback((roomId: string) => {
    setStateMap((prev) => {
      delete prev[roomId]
      return {
        ...prev,
      }
    })
  }, [])
   */

  const parseEvents = (
    events: {
      message: string
    }[],
  ) => {
    type ParsedEventRaw = {
      type: string
      payload: {
        EventName: string
        Type: string
        Id: string
        Content: string
        SendTime: string
        Attributes: Record<string, unknown>
        RequestId: string
        Sender: {
          UserId: string
          Attributes: Record<string, unknown>
        }
      }
    }
    type ParsedEvent = {
      type: string
      payload: ChatMessage
    }

    const parsedEvents: ParsedEvent[] = []

    events.forEach((raw: { message: string }) => {
      const e: ParsedEventRaw = JSON.parse(raw.message)
      switch (e.type) {
        case 'MESSAGE':
          parsedEvents.push({
            type: e.type,
            payload: {
              id: e.payload.Id,
              content: e.payload.Content,
              sender: {
                userId: e.payload.Sender.UserId,
                attributes: e.payload.Sender.Attributes,
              },
              attributes: e.payload.Attributes,
              sendTime: new Date(e.payload.SendTime),
              requestId: e.payload.RequestId,
            },
          } as ParsedEvent)
          break
        case 'EVENT':
          if (e.payload.EventName === 'aws:DELETE_MESSAGE') {
            const existingEventIdx = parsedEvents.findIndex((parsedEvent) => {
              return parsedEvent.payload.id === e.payload.Attributes.MessageID
            })
            if (existingEventIdx > -1) {
              parsedEvents.splice(existingEventIdx, 1)
            }
          }
          break
      }
    })
    return parsedEvents
  }

  const addMessage = useCallback((roomId: string, message: ChatMessage) => {
    setStateMap((prev) => {
      if (prev[roomId]) {
        const prevMap = prev[roomId]?.messages
        const prevValue = prevMap.get(message.id)

        if (prevValue === message) {
          return {
            ...prev,
            [roomId]: {
              ...prev[roomId],
              messages: prevMap,
            },
          }
        }

        const nextMap = new Map(prevMap)
        nextMap.set(message.id, message)

        return {
          ...prev,
          [roomId]: {
            ...prev[roomId],
            messages: nextMap,
          },
        }
      }
      return prev
    })
  }, [])

  const removeMessage = useCallback((roomId: string, msgId: string) => {
    setStateMap((prev) => {
      const nextMap = new Map(prev[roomId]?.messages)
      const isDeleted = nextMap.delete(msgId)
      return isDeleted
        ? {
            ...prev,
            [roomId]: {
              ...prev[roomId],
              messages: nextMap,
            },
          }
        : prev
    })
  }, [])

  //const [refMap, setRefMap] = useState<Record<string, ChatRoom>>({})
  const [typingUsers, setTypingUsers] = useState<
    Record<string, Record<string, User | null>>
  >({})

  useEffect(() => {
    if (lastSgMessage) {
      const msg = lastSgMessage
      if (msg.msg === 'chat') {
        if (msg.action === 'typing') {
          setTypingUsers((prev) => {
            return {
              ...prev,
              [msg.roomId]: {
                ...(prev[msg.roomId] || {}),
                [msg.userId]: msg.user || null,
              },
            }
          })
        } else if (msg.action === 'stop-typing') {
          setTypingUsers((prev) => {
            return {
              ...prev,
              [msg.roomId]: {
                ...(prev[msg.roomId] || {}),
                [msg.userId]: null,
              },
            }
          })
        }
      }
    }
  }, [lastSgMessage, setTypingUsers])

  useEffect(() => {
    console.log('typingUsers', typingUsers)
  }, [typingUsers])

  const loadPreviousMessages = useCallback(
    (roomId: string, backToken?: string, counter = 0, cb?: () => void) => {
      const ref = stateMap[roomId]?.room

      if (ref) {
        setLoading(roomId, true)
        // initial load will be without backToken
        const p = getSgService().listChat(
          encodeURIComponent(ref.arn()),
          backToken,
        )

        p.then((res) => {
          const result = res.events?.length > 0 ? parseEvents(res.events) : []
          const nextCounter = counter + result.length

          setStateMap((prev) => {
            const prevMap = prev[roomId]?.messages
            const prevMapArr = prevMap
              ? Array.from(prev[roomId]?.messages)
              : undefined
            const nextMap: Map<string, ChatMessage> = new Map()
            for (let i = 0; i < result.length; i++) {
              const msg = result[i]
              if (!nextMap.has(msg.payload.id)) {
                nextMap.set(msg.payload.id, msg.payload)
              }
            }

            if (prevMapArr) {
              for (let i = prevMapArr.length - 1; i >= 0; i--) {
                const [, msg] = prevMapArr[i]
                if (!nextMap.has(msg.id)) {
                  nextMap.set(msg.id, msg)
                }
              }
            }

            return {
              ...prev,
              [roomId]: {
                ...prev[roomId],
                messages: nextMap,
                curBackToken: res.nextBackwardToken,
                prevBackToken: backToken,
              },
            }
          })

          if (
            res.nextBackwardToken?.replace('b/', '') !==
            res.nextForwardToken?.replace('f/', '')
          ) {
            if (nextCounter < 50) {
              loadPreviousMessages(
                roomId,
                res.nextBackwardToken,
                nextCounter,
                cb,
              )
            }
          } else {
            setStateMap((prev) => {
              return {
                ...prev,
                [roomId]: {
                  ...prev[roomId],
                  curBackToken: undefined,
                  prevBackToken: undefined,
                },
              }
            })
          }
        }).finally(() => {
          cb?.()
          setLoading(roomId, false)
        })
      }
    },
    [stateMap, setLoading],
  )

  useEffect(() => {
    if (!initialLoadRef.current[activeRoom] && activeRoom) {
      loadPreviousMessages(activeRoom, undefined, 0, () => {
        initialLoadRef.current = {
          ...initialLoadRef.current,
          [activeRoom]: activeRoom,
        }
      })
    }
  }, [activeRoom, loadPreviousMessages, initialLoadRef])

  return (
    <ChatDialogContext.Provider
      value={{
        activeRoom,
        setActiveRoom,
        owner,
        stateMap,
        setStateMap,
        open,
        setOpen,
        updateMap,
        addMessage,
        removeMessage,
        typingUsers,
        setTypingUsers,
        loadPreviousMessages,
        isLoading,
        initialLoadRef,
      }}
    >
      {children}
      <ChatDialogAuto />
    </ChatDialogContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export function useChatDialog() {
  return useContextOrThrow(ChatDialogContext)
}
