/*import { useCallback, useMemo } from 'react'

import useAuthContext from '@/hooks/useAuthProvider.ts'


export default function useCreateOrJoinChatRoom() {
  const { user: currentUser } = useAuthContext()

  return useMemo(() => {}, [])
}

export function useCreateOrJoinChatRoomCb() {
  const { user: currentUser } = useAuthContext()

  return useCallback(() => {}, [])
}
*/

import React, { useCallback, useMemo, useRef } from 'react'

import { useChatDialog } from '@/components/Chat/ChatDialog.tsx'
import useAppContext from '@/hooks/useAppProvider.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import ChatRoom from '@/providers/ChatRoomProvider/ChatRoom.ts'

export function useCreateOrJoinChatRoom(
  roomId: Oid,
  chatArn: string,
  participants: string[],
): [ChatRoom, React.MutableRefObject<ChatRoom | undefined>] {
  const { user: currentUser } = useAuthContext()
  const { setActiveRoom, addMessage, removeMessage, updateMap } =
    useChatDialog()
  const { sendSgMessage } = useAppContext()
  const chatRoomRef = useRef<ChatRoom>()

  return [
    useMemo(() => {
      function onConnect() {
        setActiveRoom(roomId.$oid)
      }

      const cr = new ChatRoom({
        roomId: roomId.$oid,
        chatArn,
        participants,
        eventMap: {
          connect: onConnect,
        },
        wsSendMessage: sendSgMessage,
        updateState: updateMap,
        addMessage,
        removeMessage,
        currentUser: currentUser!,
      })

      chatRoomRef.current = cr
      return cr
    }, [
      currentUser,
      chatArn,
      roomId,
      updateMap,
      addMessage,
      removeMessage,
      setActiveRoom,
      chatRoomRef,
      participants,
      sendSgMessage,
    ]),
    chatRoomRef,
  ]
}

export function useCreateOrJoinChatRoomCb(): [
  (
    roomId: Oid,
    chatArn: string,
    participants: string[],
    connectCb?: (roomId: string) => void,
  ) => ChatRoom,
  React.MutableRefObject<ChatRoom | undefined>,
] {
  const { user: currentUser } = useAuthContext()
  const { setActiveRoom, addMessage, removeMessage, updateMap } =
    useChatDialog()
  const { sendSgMessage } = useAppContext()
  const chatRoomRef = useRef<ChatRoom>()

  return [
    useCallback(
      (
        roomId: Oid,
        chatArn: string,
        participants: string[],
        connectCb?: (roomId: string) => void,
      ) => {
        function onConnect() {
          setActiveRoom(roomId.$oid)
          connectCb?.(roomId.$oid)
        }

        const cr = new ChatRoom({
          roomId: roomId.$oid,
          chatArn,
          participants,
          eventMap: {
            connect: onConnect,
          },
          wsSendMessage: sendSgMessage,
          updateState: updateMap,
          addMessage,
          removeMessage,
          currentUser: currentUser!,
        })

        chatRoomRef.current = cr
        return cr
      },
      [
        currentUser,
        updateMap,
        addMessage,
        removeMessage,
        setActiveRoom,
        chatRoomRef,
        sendSgMessage,
      ],
    ),
    chatRoomRef,
  ]
}
