import { SafeguardingChildrenPath } from '@/constants/constants.ts'
import { PathKind } from '@/helpers/path.ts'

export default {
  path: PathKind.Safeguarding,
  lazy: async () => {
    const { default: Component } = await import(
      '@/pages/SafeguardingPage/index.tsx'
    )
    return { Component }
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: Component } = await import(
          '@/pages/SafeguardingPage/SafeguardingDashboardPage/index.tsx'
        )
        return { Component }
      },
    },
    {
      path: `${SafeguardingChildrenPath.Day}/:date`,
      lazy: async () => {
        const { default: Component } = await import(
          '@/pages/SafeguardingPage/SafeguardingDayPage/index.tsx'
        )
        return { Component }
      },
    },
    {
      path: SafeguardingChildrenPath.EDTeach,
      lazy: async () => {
        const { default: Component } = await import(
          '@/pages/SharedPage/EDTeach/index.tsx'
        )
        return { Component }
      },
    },
    {
      path: SafeguardingChildrenPath.Overview,
      lazy: async () => {
        const { default: Component } = await import(
          '@/pages/SafeguardingPage/SafeguardingOverviewPage/index.tsx'
        )
        return { Component }
      },
    },
  ],
}
