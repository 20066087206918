import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import tinycolor from 'tinycolor2'

import LoggedNavLink from '@/components/Link/LoggedNavLink.tsx'
import { AdminChildrenPath, AdminCrudPath } from '@/constants/constants.ts'
import { getPath, mergePath, PathKind } from '@/helpers/path.ts'

export default function SubjectOption<
  T extends {
    id: string
    title: string
    icon?: string | null
    color?: string | null
  },
>({
  subject,
  noIconMargin,
  noIconPadding,
  noIconGap,
  className,
  iconClassName,
  onClick,
  tag: Tag = 'div',
  link,
  wrapperClassName,
  bgOn,
  textClassName,
  titleText,
  size = 'md',
  extra,
}: {
  subject: T
  noIconMargin?: boolean
  noIconPadding?: boolean
  noIconGap?: boolean
  className?: string
  iconClassName?: string
  onClick?: (subject: T) => void
  tag?: 'div' | 'button'
  link?: boolean | string
  wrapperClassName?: string
  bgOn?: boolean
  textClassName?: string
  titleText?: boolean
  size?: 'md' | 'lg'
  extra?: ReactNode
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const linkProps: any = link
    ? {
        to: getPath(
          PathKind.Admin,
          typeof link === 'boolean'
            ? mergePath(
                AdminChildrenPath.SUBJECTS,
                subject.id,
                AdminCrudPath.DETAIL,
              )
            : mergePath(link, subject.id, AdminCrudPath.DETAIL),
        ) as never,
      }
    : undefined

  const color = useMemo(() => {
    return subject?.color ? tinycolor(subject?.color) : undefined
  }, [subject?.color])

  const ElTag = link ? LoggedNavLink : Tag

  return (
    <ElTag
      {...linkProps}
      onClick={() => {
        onClick?.(subject)
      }}
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '--subject-color': `${subject?.color || ''}`,
      }}
      className={clsx(
        twMerge(
          'flex-c',
          !noIconGap && 'gap-2',
          wrapperClassName,
          bgOn && 'bg-[var(--subject-color)]',
        ),
        bgOn ? (color?.isDark() ? 'text-white' : 'text-ed-bg') : '',
      )}
    >
      {subject?.icon && (
        <div
          className={clsx(
            !bgOn && 'bg-[var(--subject-color)] rounded-full cc',
            !noIconMargin ? 'm-1 p-1' : !noIconPadding ? 'p-2' : '',
            className,
          )}
        >
          <Sym
            className={clsx(
              size === 'md' ? '!text-[20px]' : '!text-[32px]',
              color?.isDark() ? 'text-white' : 'text-ed-bg',
              iconClassName,
            )}
          >
            {subject?.icon}
          </Sym>
        </div>
      )}
      <span className={textClassName}>
        {titleText ? (
          <>
            <div className="text-body text-blue-gray-500">Subject</div>
            <div>{subject?.title}</div>
          </>
        ) : (
          subject?.title
        )}
        {extra}
      </span>
    </ElTag>
  )
}
