import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  USER_CHECK_LOGIN,
  USER_IS_LOGGED_IN,
  USER_STORAGE_KEY,
} from '@/constants/auth.ts'
import useEffectOnce from '@/hooks/useEffectOnce.ts'
import useGlobalContext from '@/hooks/useGlobalContext'
import { getApiService } from '@/services/api.ts'
import { getAuthService } from '@/services/auth.ts'

interface AuthContextValue {
  user?: User
  setUser: Dispatch<SetStateAction<User | undefined>>
  login: (user: string, pwd: string) => Promise<void | string>
  resetPass: (email: string) => Promise<void>
  verifyPass: (email: string, pwd: string) => Promise<void | string>
  confirmResetPass: (
    email: string,
    pwd: string,
    confirmationCode: string,
  ) => Promise<void | string>
  logout: (cleanup?: () => void) => Promise<void>
  logoutClean: () => void
  //error: string | null
  //setError: SetState<string | null>
  //ivsToken: {
  //  cameraToken: { token: string }
  //  screenToken: { token: string }
  //} | null
}

export const AuthContext = createContext<AuthContextValue>({
  setUser: () => null,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  logoutClean: () => null,
  resetPass: () => Promise.resolve(),
  verifyPass: () => Promise.resolve(),
  confirmResetPass: () => Promise.resolve(),
  //error: null,
  //setError: () => null,
  //ivsToken: null,
})

function getUserFromStorage(): User | undefined {
  const fromStorage = localStorage?.getItem(USER_STORAGE_KEY)
  if (fromStorage) {
    try {
      return JSON.parse(fromStorage)
    } catch (e) {
      console.error()
    }
  }
}

export default function AuthProvider({ children }: { children: ReactNode }) {
  const initialUser = getUserFromStorage()
  const { logger } = useGlobalContext()
  /*const [ivsToken, setIvsToken] = useLocalStorageState<null | {
    cameraToken: {
      token: string
    }
    screenToken: {
      token: string
    }
  }>('USER_IT', {
    defaultValue: null,
  })

  useEffect(() => {
    console.log({
      ivsToken,
    })
  }, [ivsToken])*/
  //const [error, setError] = useState<string | null>(null)
  const [user, setUser] = useState<User | undefined>(initialUser)

  /*const getIvsToken = useCallback(
    (user: User) => {
      if (user && !ivsToken) {
        logger.current
          .joinRoom({
            stageArn: 'arn:aws:ivs:eu-west-1:337205016122:stage/9bwrxvyTaCLa',
            duration: 300,
          })
          .then((res) => setIvsToken(res))
      }
    },
    [ivsToken],
  )*/

  /*useEffect(() => {
    if (user && !ivsToken) {
      getIvsToken(user)
    }
  }, [ivsToken, user])
  */

  const handleLogin = useCallback(
    (user: User) => {
      localStorage?.setItem(USER_CHECK_LOGIN, '0')
      localStorage?.setItem(USER_IS_LOGGED_IN, '1')
      localStorage?.setItem(USER_STORAGE_KEY, JSON.stringify(user))
      setUser(user)
      logger.current.logLogin({
        email: user.email,
        userId: user.id,
      })
    },
    [logger],
  )

  const logoutClean = useCallback(() => {
    localStorage?.removeItem(USER_STORAGE_KEY)
    localStorage?.removeItem(USER_IS_LOGGED_IN)
    localStorage?.removeItem(USER_CHECK_LOGIN)
    setUser(undefined)
    //setIvsToken(null)
  }, [])

  useEffectOnce(() => {
    getApiService().setErrorCb((e) => {
      if (typeof e.status === 'number' && e.status === 401) {
        logoutClean()
      }
    })
  })

  const logout = useCallback(
    async (cleanup?: () => void) => {
      try {
        cleanup?.()
        logoutClean()
        await getAuthService().logout()
      } catch (e) {
        logoutClean()
        //console.error(e)
      }
    },
    [logoutClean],
  )

  const login = useCallback(
    async (username: string, pwd: string) => {
      try {
        const user = await getAuthService().login(username, pwd)
        handleLogin(user)
      } catch (e: unknown) {
        const msg = (e as { message: string }).message
        throw Error(msg)
      }
    },
    [handleLogin],
  )

  const resetPass = useCallback(async (email: string) => {
    try {
      await getAuthService().forgotPass(email)
    } catch (e) {
      const msg = (e as Error).message
      throw Error(msg)
    }
  }, [])

  const confirmResetPass = useCallback(
    async (email: string, pwd: string, confirmationCode: string) => {
      try {
        await getAuthService().confirmForgotPass(email, pwd, confirmationCode)
        //handleLogin(user)
      } catch (e) {
        console.log('CONFIRM RESET PASS', e)
        const msg = (e as Error).message
        throw Error(msg)
      }
    },
    [],
  )

  const verifyPass = useCallback(
    async (email: string, password: string) => {
      try {
        const user = await getAuthService().verifyPass(email, password)
        handleLogin(user)
      } catch (e) {
        const msg = (e as { message: string }).message
        throw Error(msg)
      }
    },
    [handleLogin],
  )

  useEffect(() => {
    if (!user) {
      const fromStorage = getUserFromStorage()
      if (fromStorage) {
        setUser(fromStorage)
      }
    }
  }, [setUser, user])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        logoutClean,
        resetPass,
        confirmResetPass,
        verifyPass,
        //error,
        //setError,
        //ivsToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
