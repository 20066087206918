import { Input } from '@material-tailwind/react'
import { ChangeEvent, useCallback, useMemo } from 'react'

import InputRemoveButton from '@/components/InputRemoveButton.tsx'
import { type SearchContextValue } from '@/providers/SearchProvider.tsx'

export default function Search({
  ctx,
  className,
  btnClassName,
  plain,
}: {
  ctx: SearchContextValue
  className?: string
  btnClassName?: string
  plain?: boolean
}) {
  const { search, setSearch, debounced } = ctx
  const btn = useMemo(() => {
    return (
      <InputRemoveButton
        className={btnClassName}
        onClick={() => {
          setSearch('')
          debounced('')
          debounced.flush()
        }}
      />
    )
  }, [setSearch, btnClassName, debounced])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const s = e.currentTarget.value
      setSearch(s)
    },
    [setSearch],
  )

  return plain ? (
    <div className="flex-c relative">
      <input
        placeholder="Search"
        value={search || ''}
        onChange={handleChange}
        className={className}
      />
      {search && btn}
    </div>
  ) : (
    <Input
      label="Search"
      value={search || ''}
      onChange={handleChange}
      icon={search ? btn : undefined}
      className={className}
    />
  )
}
