import { defer } from 'react-router-dom'

import { getSgService } from '@/services/sg.ts'

export function roomLoader() {
  /*
  _args: LoaderFunctionArgs
   */
  try {
    //request?.url?.endsWith('new') || request?.url?.endsWith('new/')
    return defer({
      createRoomResponse: getSgService()
        .createRoom()
        .then((res) => {
          console.log('room IvsJoinResponse', res)
          return res
        }),
    })
  } catch (error) {
    console.error(error)
    //return redirect('/')
  }
}
