import { SquircleButton, Sym } from '@edclass/fe-ui'
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset as fuiOffset,
  OffsetOptions,
  Placement,
  size as fuiSize,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import type { color } from '@material-tailwind/react/types/components/button'
import clsx from 'clsx'
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion'
import { ReactNode, useMemo, useState } from 'react'

import { DEFAULT_FLOATING_ANIMATION } from '@/constants/constants.ts'
import { queueMacrotask } from '@/helpers/media.ts'

export default function Fab({
  placement = 'bottom-start',
  offset = {
    mainAxis: 12,
  },
  dialogClassName,
  badge,
  icon,
  children,
  dialogWidth,
  dialogHeight,
  color,
  className,
  onClick,
  size = 'sm',
  open: openExternal,
  setOpen: setOpenExternal,
}: {
  placement?: Placement
  offset?: OffsetOptions
  dialogClassName?: string
  className?: string
  badge?: ReactNode
  icon: string
  dialogWidth?: number
  dialogHeight?: number
  children?: ReactNode
  color?: color
  onClick?: () => void
  size?: 'xl' | 'lg' | 'sm' | 'xs'
  open?: boolean
  setOpen?: SetState<boolean>
}) {
  const [openInternal, setOpenInternal] = useState(false)

  const setOpen = useMemo(() => {
    return openExternal !== undefined && setOpenExternal !== undefined
      ? setOpenExternal
      : setOpenInternal
  }, [setOpenExternal, openExternal, setOpenInternal])

  const open = useMemo(() => {
    return openExternal !== undefined ? openExternal : openInternal
  }, [openExternal, openInternal])

  const { strategy, x, y, refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange(open: boolean) {
      queueMacrotask(() => setOpen(open))
    },
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      fuiOffset(offset),
      flip({ padding: 16 }),
      fuiSize({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        apply({ elements }: any) {
          Object.assign(elements?.floating?.style, {
            zIndex: 99,
          })
        },
        padding: 16,
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      enabled: true,
    }),
    useFocus(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context, {
      escapeKey: true,
      enabled: true,
    }),
  ])

  const props = onClick
    ? getReferenceProps({
        className,
      })
    : {}

  return (
    <>
      <div ref={onClick ? undefined : refs.setReference} {...props}>
        <SquircleButton
          badge={badge}
          onClick={onClick ? onClick : () => setOpen(true)}
          aria-label="Chat"
          size={size}
          color={color}
        >
          <Sym>{icon}</Sym>
        </SquircleButton>
      </div>
      {!onClick ? (
        <FloatingPortal root={document.getElementById('portal')}>
          <LazyMotion features={domAnimation}>
            <AnimatePresence>
              {open && (
                <FloatingFocusManager context={context} modal={false}>
                  <m.div
                    initial="unmount"
                    exit="unmount"
                    ref={refs.setFloating}
                    animate={open ? 'mount' : 'unmount'}
                    variants={DEFAULT_FLOATING_ANIMATION}
                    style={floatingStyles}
                    {...getFloatingProps({
                      className: clsx(
                        'w-[var(--fab-dialog-width)] h-[var(--fab-dialog-height)]',
                        dialogClassName,
                      ),
                      style: {
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        overflow: 'auto',
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        '--fab-dialog-width': dialogWidth
                          ? `${dialogWidth}px`
                          : '400px',
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        '--fab-dialog-height': dialogHeight
                          ? `${dialogHeight}px`
                          : 'auto',
                      },
                    })}
                  >
                    {children}
                  </m.div>
                </FloatingFocusManager>
              )}
            </AnimatePresence>
          </LazyMotion>
        </FloatingPortal>
      ) : (
        children
      )}
    </>
  )
}
