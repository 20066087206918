import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'
import { MouseEventHandler } from 'react'
import { twMerge } from 'tailwind-merge'

export default function InputRemoveButton({
  onClick,
  iconClassName,
  className,
}: {
  onClick: MouseEventHandler
  iconClassName?: string
  className?: string
}) {
  return (
    <button
      className={twMerge(
        clsx('cc flex-shrink-0 text-white/70 hover:text-white', className),
      )}
      onClick={onClick}
    >
      <Sym className={iconClassName}>close</Sym>
    </button>
  )
}
