import { Sym } from '@edclass/fe-ui'
import { Textarea } from '@material-tailwind/react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import FieldWrap from '@/components/Form/FieldWrap.tsx'
import { EDIcon } from '@/constants/constants.ts'

export default function AlertDialog({
  className,
  alert,
  onChange,
}: {
  alert: AlertFormValue
  onChange: (next: AlertFormValue) => void
  className?: string
}) {
  return (
    <div
      className={clsx(
        'rounded-md',
        twMerge('bg-white text-ed-text p-4', className),
      )}
    >
      <div className="flex flex-col gap-8">
        <div>
          <div className="text-center font-bold uppercase mb-8">Reason</div>
          <div className="grid grid-cols-3 gap-4">
            {['lesson', 'safety', 'other'].map((reason) => {
              let icon = EDIcon.Safety as string
              switch (reason) {
                case 'lesson':
                  icon = 'cast_for_education'
                  break
                case 'safety':
                  icon = EDIcon.Safeguarding
                  break
                case 'other':
                  icon = EDIcon.Help
                  break
              }
              return (
                <button
                  className={clsx(
                    'flex flex-col justify-center gap-1 w-full bg-blue-500 rounded h-32',
                    alert.kind === reason
                      ? 'bg-blue-500'
                      : 'bg-ed-bg border border-blue-500',
                  )}
                  key={reason}
                  onClick={() => {
                    onChange({
                      ...alert,
                      kind: reason as AlertKind,
                    })
                  }}
                >
                  <Sym className="!text-[48px]">{icon}</Sym>
                  <div className="capitalize font-bold">{reason}</div>
                </button>
              )
            })}
          </div>
        </div>
        {/*alert.kind === 'staff' && (
          <FieldWrap>
            <AutoCompleteStaff
              value={alert.reportedUsers}
              onChange={(n) => {
                onChange({
                  ...alert,
                  reportedUsers: n,
                })
              }}
            />
          </FieldWrap>
        )*/}
        <FieldWrap noMargin>
          <Textarea
            className="w-full"
            onChange={(e) => {
              const val = e.currentTarget.value
              onChange({
                ...alert,
                message: val,
              })
            }}
            label="Type your messages"
          />
        </FieldWrap>
      </div>
    </div>
  )
}
