import { AdminChildrenPath, AdminCrudPath } from '@/constants/constants.ts'
import AdminCurriculumsPage from '@/pages/AdminPage/AdminCurriculumsPage'
import AdminCurriculumsCRUDPage from '@/pages/AdminPage/AdminCurriculumsPage/AdminCurriculumsCRUDPage'
import AdminCurriculumsIndexPage from '@/pages/AdminPage/AdminCurriculumsPage/AdminCurriculumsIndexPage'

export default {
  path: AdminChildrenPath.CURRICULUM,
  element: <AdminCurriculumsPage />,
  children: [
    {
      index: true,
      element: <AdminCurriculumsIndexPage />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <AdminCurriculumsCRUDPage />,
    },
    {
      path: `:id`,
      element: <AdminCurriculumsCRUDPage />,
    },
  ],
}
