import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'
import { useMemo } from 'react'

import { getFileIcon, getThumbType } from '@/helpers/fs.ts'
import { getFsUrl } from '@/services/fs.ts'

export default function ChatAttachment({
  file,
  direction,
}: {
  file: FsItem
  direction: 'left' | 'right'
}) {
  const type = useMemo(() => {
    return getThumbType(file)
  }, [file])

  const item = useMemo(() => {
    const u = getFsUrl(file.key, file.bucket)

    switch (type) {
      case 'image':
        return <img className="text-body break-all" src={u} alt={file.key} />
      case 'video':
        return (
          <video
            className="text-body break-all"
            src={u}
            title={file.key}
            controls
          />
        )
      case 'audio':
        return (
          <audio
            className="text-body break-all"
            src={u}
            title={file.key}
            controls
          />
        )
      default:
        return (
          <a href={u}>
            <Sym className="text-red !text-[64px]">{getFileIcon(type)}</Sym>
            <div className="text-body break-all">{file.key}</div>
          </a>
        )
    }
  }, [file, type])

  return (
    <div
      className={clsx(
        'mb-2 last:mb-0 p-1 rounded-md',
        type !== 'image'
          ? direction === 'left'
            ? 'bg-gray-100 hover:bg-gray-200'
            : 'bg-teal-200 hover:bg-teal-300'
          : '',
      )}
    >
      {item}
    </div>
  )
}
