import { useQuery } from '@tanstack/react-query'

import { QueryKeys } from '@/constants/query.ts'
import useIsQueryLoading from '@/hooks/query/useIsQueryLoading.ts'
import { useSearchAndParams } from '@/hooks/useSearchAndParams.ts'
import { Params } from '@/services/params.ts'
import { getSgService } from '@/services/sg.ts'

export default function useOnlineEDTeachStudents(initialParams?: ReqParams) {
  const { params, ...restParams } = useSearchAndParams(initialParams)

  const query = useQuery({
    queryKey: [QueryKeys.LsStudentOnline, params],
    queryFn: async ({ queryKey: [, params] }) => {
      return getSgService().listEDTeachStudentOnline(
        Params.fromObject(params as ReqParams),
      )
    },
  })
  const loading = useIsQueryLoading(query, restParams)

  return {
    ...query,
    ...restParams,
    loading,
    params,
  }
}
