import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import FloatingAlertButton from '@/components/Buttons/Fab/FloatingAlertButton.tsx'
import FloatingChatButton from '@/components/Buttons/Fab/FloatingChatButton.tsx'
import {
  ChatDialog,
  ChatDialogPanel,
  useChatDialog,
} from '@/components/Chat/ChatDialog.tsx'
import useAppContext from '@/hooks/useAppProvider.ts'
import useCheckRole from '@/hooks/useCheckRole.ts'
import { TeacherPageProvider } from '@/pages/TeacherPage/TeacherPageProvider.tsx'
import TeacherSidebar from '@/pages/TeacherPage/TeacherSidebar.tsx'

function ChatBtn() {
  const { open, setOpen } = useChatDialog()
  return (
    <FloatingChatButton open={open} setOpen={setOpen} size="xs">
      <ChatDialogPanel />
    </FloatingChatButton>
  )
}

function PageImpl() {
  /*const { recordingFlag, setRecordingFlag } = useTeacherPage()
  const { joinResponse } = useLoaderData() as {
    joinResponse: PromiseLike<IvsJoinResponse>
  }*/

  return (
    <Suspense fallback={<div>loading teacher elements...</div>}>
      <Outlet />
      {/*
        <Await
        resolve={joinResponse}
        errorElement={<div>loading join error</div>}
      >
        <Outlet />
        <EnableScreenCaptureModal
          flags={recordingFlag}
          onChange={setRecordingFlag}
        />
        <EnableCameraModal flags={recordingFlag} onChange={setRecordingFlag} />
      </Await>
         */}
      <div className="flex-c justify-center gap-4 w-[var(--ed-sidebar-width)] px-8 fixed bottom-4 left-0">
        <FloatingAlertButton size="xs" />
        <ChatBtn />
      </div>
    </Suspense>
  )
}

function PageImplStaff() {
  return (
    <Suspense>
      <Outlet />
      <div className="flex-c justify-center gap-4 w-[var(--ed-sidebar-width)] px-8 fixed bottom-4 left-0">
        <FloatingAlertButton size="xs" />
        <ChatBtn />
      </div>
    </Suspense>
  )
}

export default function TeacherPage() {
  const [canView, user] = useCheckRole(['ROLE_EDCLASS_STAFF', 'ROLE_ROOT'])

  const { sidebar } = useAppContext()

  return canView ? (
    <TeacherPageProvider>
      <div className="p-4">
        <sidebar.In>
          <TeacherSidebar />
        </sidebar.In>
        <ChatDialog>
          {user.teachingSubjects ? <PageImpl /> : <PageImplStaff />}
        </ChatDialog>
      </div>
    </TeacherPageProvider>
  ) : null
}
