import { lazy } from 'react'

import {
  AdminChildrenPath,
  AdminCrudPath,
  AdminSchoolChildrenPath,
} from '@/constants/constants.ts'
import academicTermRoute from '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsAcademicTermsPage/route'
import formRoute from '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsFormPage/route'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(() => import('@/pages/AdminPage/AdminSchoolsPage/index.tsx'))

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () => import('@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsIndexPage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(
  () => import('@/pages/AdminPage/AdminSchoolsPage/AdminSchoolCreatePage.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(
  () => import('@/pages/AdminPage/AdminSchoolsPage/AdminSchoolEditPage.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Student = lazy(
  () => import('@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsStudentsPage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const StudentCreate = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsStudentsPage/AdminSchoolsStudentsCreatePage.tsx'
    ),
)
// eslint-disable-next-line react-refresh/only-export-components
const StudentEdit = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsStudentsPage/AdminSchoolsStudentsEditPage.tsx'
    ),
)
// eslint-disable-next-line react-refresh/only-export-components
const Seat = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsSeatsPage/index.tsx'
    ),
)
// eslint-disable-next-line react-refresh/only-export-components
const SeatCandidate = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsSeatsPage/AdminSchoolsSeatsCandidatePage.tsx'
    ),
)
// eslint-disable-next-line react-refresh/only-export-components
const SeatTimetable = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsSeatsPage/AdminSchoolsSeatsTimetablePage.tsx'
    ),
)
// eslint-disable-next-line react-refresh/only-export-components
const StudentTimetable = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsStudentsPage/AdminSchoolsStudentsTimetablePage.tsx'
    ),
)
// eslint-disable-next-line react-refresh/only-export-components
const StudentPathway = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsStudentsPage/AdminSchoolsStudentsPathwayPage.tsx'
    ),
)
// eslint-disable-next-line react-refresh/only-export-components
const SeatSubscription = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsSeatsPage/AdminSchoolsSeatsSubscriptionPage.tsx'
    ),
)
// eslint-disable-next-line react-refresh/only-export-components
const Branch = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsBranchPage/index.tsx'
    ),
)
// eslint-disable-next-line react-refresh/only-export-components
const Timetable = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSchoolsPage/AdminSchoolsTimetablePage/index.tsx'
    ),
)

export default {
  path: AdminChildrenPath.SCHOOLS,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    /*
    {
      path: AdminSchoolChildrenPath.STUDENT_QUESTIONS,
      element: <StudentQuestion />,
    },
     */
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:id`,
      element: <Edit />,
    },
    {
      path: `:id/students`,
      element: <Student />,
    },
    {
      path: `:id/students/${AdminCrudPath.CREATE}`,
      element: <StudentCreate />,
    },
    {
      path: `:id/students/:studentId/${AdminCrudPath.CREATE}`,
      element: <StudentEdit />,
    },
    {
      path: `:id/${AdminSchoolChildrenPath.SEATS}`,
      element: <Seat />,
    },
    {
      path: `:id/${AdminSchoolChildrenPath.SEATS}/${AdminCrudPath.CREATE}`,
      element: <SeatCandidate />,
    },
    {
      path: `:id/${AdminSchoolChildrenPath.SEATS}/:seatId/${AdminSchoolChildrenPath.TIMETABLES}`,
      element: <SeatTimetable />,
    },
    {
      path: `:id/${AdminSchoolChildrenPath.STUDENTS}/:studentId/${AdminSchoolChildrenPath.TIMETABLES}`,
      element: <StudentTimetable />,
    },
    {
      path: `:id/${AdminSchoolChildrenPath.STUDENTS}/:studentId/${AdminSchoolChildrenPath.PATHWAYS}`,
      element: <StudentPathway />,
    },
    {
      path: `:id/${AdminSchoolChildrenPath.SEATS}/${AdminSchoolChildrenPath.SUBSCRIPTIONS}`,
      element: <SeatSubscription />,
    },
    {
      path: `:id/${AdminSchoolChildrenPath.BRANCH}`,
      element: <Branch />,
    },
    {
      path: `:id/${AdminSchoolChildrenPath.TIMETABLES}`,
      element: <Timetable />,
    },
    academicTermRoute,
    formRoute,
  ],
}
