import { useQuery } from '@tanstack/react-query'

import { QueryKeys } from '@/constants/query.ts'
import { getApiService } from '@/services/api.ts'

export default function useStudent(studentId: string) {
  return useQuery({
    queryKey: [QueryKeys.Student, studentId],
    queryFn: async ({ queryKey: [, studentId] }) => {
      return getApiService().getStudent(studentId)
    },
  })
}
