import BoringAvatar from 'boring-avatars'
import clsx from 'clsx'
import { CSSProperties, useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Tooltip from '@/components/Tooltip'
import { getUserDisplayName } from '@/helpers/user.ts'
import { getFsUrl } from '@/services/fs.ts'

export default function Avatar({
  tip,
  user,
  size = 32,
  className,
  style,
  imgClassName,
  src,
}: {
  size?: number
  tip?: boolean
  user?: Partial<SimpleUser>
  className?: string
  imgClassName?: string
  style?: CSSProperties
  src?: string
}) {
  const name = useMemo(() => {
    return getUserDisplayName(user as SimpleUser)
  }, [user])

  useEffect(() => {
    console.log('avatar name', name)
  }, [name])

  const [fromSrc, setFromSrc] = useState<string | undefined>(
    src
      ? src
      : user?.id
        ? `${import.meta.env.VITE_FS_API_URL}/api/data/avatar/by-id/${user.id}`
        : undefined,
  )

  const avatar = (
    <div
      className={clsx(twMerge('rounded-full flex-shrink-0', className))}
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        //'--img-size': `${size}px`,
        ...style,
      }}
    >
      {user?.picture || fromSrc ? (
        <img
          src={user?.picture ? getFsUrl(user.picture) : fromSrc}
          onError={() => {
            if (fromSrc) {
              setFromSrc(undefined)
            }
          }}
          width={size}
          height={size}
          className={clsx(twMerge('rounded-[inherit]', imgClassName))}
        />
      ) : (
        <BoringAvatar variant="beam" size={size} name={name} />
      )}
    </div>
  )
  return tip ? <Tooltip content={name || 'N/A'}>{avatar}</Tooltip> : avatar
}
