import {
  AUDIO_EXTS,
  AUDIO_MIMES,
  IMAGE_EXTS,
  IMAGE_MIMES,
  VIDEO_EXTS,
  VIDEO_MIMES,
} from '@/constants/fs.ts'

export type FileIconType =
  | 'audio'
  | 'video'
  | 'image'
  | 'doc'
  | 'unknown'
  | 'pdf'
  | 'sheet'

export function getFileIcon(type: FileIconType) {
  switch (type) {
    case 'audio':
      return 'music_note'
    case 'video':
      return 'movie'
    case 'image':
      return 'image'
    case 'doc':
      return 'news'
    case 'sheet':
      return 'table'
    case 'pdf':
      return 'picture_as_pdf'
    default:
      return 'unknown_document'
  }
}

export function getExtension(item?: FsItem) {
  const split = item?.key.split('.') ?? []
  return split[split.length - 1]
}

export function getThumbTypeFromStr(path: string) {
  if (IMAGE_EXTS.includes(path)) {
    return 'image'
  } else if (AUDIO_EXTS.includes(path)) {
    return 'audio'
  } else if (VIDEO_EXTS.includes(path)) {
    return 'video'
  } else if (['doc', 'docx', 'odt', 'txt', 'md'].includes(path)) {
    return 'doc'
  } else if (['xls', 'xlsx', 'csv', 'tsv', 'ods'].includes(path)) {
    return 'sheet'
  } else if (path.includes('pdf')) {
    return 'pdf'
  } else {
    return 'unknown'
  }
}
export function getThumbType(item?: FsItem) {
  const ct = item?.contentType
  const ext = getExtension(item)

  if (typeof ext !== 'string') {
    return 'unknown'
  }
  if (ct && IMAGE_MIMES.includes(ct)) {
    return 'image'
  } else if (ct && AUDIO_MIMES.includes(ct)) {
    return 'audio'
  } else if (ct && VIDEO_MIMES.includes(ct)) {
    return 'video'
  } else if (['doc', 'docx', 'odt', 'txt', 'md'].includes(ext)) {
    return 'doc'
  } else if (['xls', 'xlsx', 'csv', 'tsv', 'ods'].includes(ext)) {
    return 'sheet'
  } else if (ext.includes('pdf') || ct === 'application/pdf') {
    return 'pdf'
  } else {
    return 'unknown'
  }
}
