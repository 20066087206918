import clsx from 'clsx'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export default function FieldWrap({
  className,
  noMargin = false,
  children,
}: {
  className?: string
  noMargin?: boolean
  children?: ReactNode
}) {
  return (
    <div
      className={twMerge(
        clsx('flex flex-col gap-4', !noMargin && 'mb-4', className),
      )}
    >
      {children}
    </div>
  )
}
