import { Sym } from '@edclass/fe-ui'
import { ChatUser } from 'amazon-ivs-chat-messaging'
import clsx from 'clsx'
import { m } from 'framer-motion'
import { forwardRef, useMemo } from 'react'

import Avatar from '@/components/Avatar'
import ChatAttachment from '@/components/Chat/ChatAttachment.tsx'
import { useChatDialog } from '@/components/Chat/ChatDialog.tsx'
import ChatText from '@/components/Chat/ChatText.tsx'
import { toLocaleTime } from '@/helpers/date.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import { getFsUrl } from '@/services/fs.ts'

import ChatVoicePlayer from './ChatVoicePlayer.tsx'

type ChatItemProps = {
  message: string
  sendTime: Date
  id: string
  direction: 'left' | 'right'
  createdAt: Date
  sender: ChatUser
  lastReadMsg: null | SgWsChatReadStatusResponse['detail']
}

const ChatItem = forwardRef<HTMLDivElement, ChatItemProps>(function ChatItem(
  {
    message,
    direction,
    createdAt,
    sender,
    sendTime,
    lastReadMsg,
    id,
  }: ChatItemProps,
  ref,
) {
  //const owned = useMemo(() => direction === 'right', [direction])

  const { stateMap, activeRoom } = useChatDialog()

  const curState = useMemo(() => {
    return stateMap[activeRoom!]
  }, [stateMap, activeRoom])

  const msg: IvsChatContent | null = useMemo(() => {
    try {
      return JSON.parse(message) as IvsChatContent
    } catch {
      return null
    }
  }, [message])

  const { user: currentUser } = useAuthContext()

  const readState: null | 'partial' | true = useMemo(() => {
    if (lastReadMsg) {
      const readList = Object.entries(lastReadMsg)
      let readBySendTimeCount = 0
      // do not include self
      const participantSize = curState.room.getParticipantSize() - 1
      for (const [userId, rl] of readList) {
        if (userId !== currentUser?.id && rl?.lastReadMessageSendTime) {
          const lastDate = new Date(rl.lastReadMessageSendTime)
          const sec = sendTime.getTime()
          const lastReadSecond = lastDate.getTime()

          if (sec <= lastReadSecond) {
            readBySendTimeCount++
          } else if (id === rl.lastReadMessageId) {
            readBySendTimeCount++
          }
        }
      }

      return readBySendTimeCount >= participantSize ? true : 'partial'
    }
    return null
  }, [curState, currentUser, lastReadMsg, id, sendTime])

  const voice = useMemo(() => {
    if (msg?.type === 'voice') {
      try {
        return JSON.parse(msg.msg) as FsItem
      } catch {
        return null
      }
    }
    return null
  }, [msg])

  if (!msg) {
    return null
  }

  return (
    <m.div
      ref={ref}
      /*animate="visible"
        initial="from"
        exit="to"
        variants={{
          from: {
            opacity: 0.5,
            scale: 0.95,
          },
          to: {
            opacity: 1,
            scale: 1,
          },
        }}*/
      className={clsx(
        'flex gap-2 w-auto',
        'max-w-[80%]',
        direction === 'left' ? 'mr-auto' : 'ml-auto',
      )}
    >
      {direction === 'left' && (
        <Avatar
          user={{
            id: sender.userId,
            ...sender.attributes,
          }}
          size={24}
          className="mt-1"
          src={`${import.meta.env.VITE_EVENT_API_URL}/api/data/avatar/by-id/${sender.userId}`}
        />
      )}
      <div
        className={clsx(
          'flex flex-col',
          'rounded shadow-sm p-2',
          direction === 'left' ? 'mr-auto bg-white' : 'ml-auto bg-teal-100',
        )}
      >
        <div>
          {msg.type === 'files' ? (
            <div>
              {msg.files?.map((f, i) => {
                return <ChatAttachment direction={direction} key={i} file={f} />
              })}
            </div>
          ) : msg.type === 'voice' && voice ? (
            <div className="min-w-[200px]">
              <ChatVoicePlayer src={getFsUrl(voice.key, voice.bucket) || ''} />
            </div>
          ) : (
            <div className="break-all">
              <ChatText text={msg.msg} />
            </div>
          )}
          {id}
        </div>
        <div className="flex-c gap-1 justify-end">
          <div className="flex w-full text-[10px] text-blue-gray-500 font-mono">
            {toLocaleTime(createdAt)}
          </div>
          {direction === 'right' && (
            <div
              className={clsx(
                readState === true ? 'text-blue-800' : 'text-gray-600',
              )}
            >
              {readState === true ? (
                <Sym className="!text-[18px]">done_all</Sym>
              ) : (
                <Sym className="!text-[18px]">check</Sym>
              )}
            </div>
          )}
        </div>
      </div>
    </m.div>
  )
})
export default ChatItem
