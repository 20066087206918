import { Spinner } from '@material-tailwind/react'
import { useMemo } from 'react'
import Embed from 'react-tiny-oembed-alt'

import { CHAT_URL_REGEX } from '@/constants/constants.ts'
import { IMAGE_EXTS } from '@/constants/fs.ts'

type ChatTextProps = {
  text: string
}

export default function ChatText({ text }: ChatTextProps) {
  /*
  const matches = text.match(CHAT_URL_REGEX) || []

  const link = useCallback((url: string) => {
    return (
      <a className="link" href={url} rel="noopener noreferrer" target="_blank">
        {url}
      </a>
    )
  }, [])
   */

  const els = useMemo(() => {
    const elements: (JSX.Element | string)[] = []
    let lastIndex = 0

    text.replace(CHAT_URL_REGEX, (match, _, offset) => {
      // Push the text before the link
      if (offset > lastIndex) {
        elements.push(
          <span key={lastIndex}>{text.slice(lastIndex, offset)}</span>,
        )
      }

      console.log('MATCH', match)
      // Push the link
      const href = match.startsWith('http') ? match : `https://${match}`
      const extSplit = href.split('.')
      const ext = extSplit[extSplit.length - 1]
      const isImg = IMAGE_EXTS.includes(ext.toLowerCase())

      elements.push(
        <span key={offset}>
          <Embed
            url={href}
            FallbackElement={<></>}
            LoadingFallbackElement={<Spinner />}
          />
          <a
            href={href}
            className="link"
            target="_blank"
            rel="noreferrer noopener"
          >
            {isImg ? <img src={href} /> : match}
          </a>
        </span>,
      )

      lastIndex = offset + match.length
      return match // Return value is ignored
    })

    // Push remaining text after the last link
    if (lastIndex < text.length) {
      elements.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>)
    }

    return elements
  }, [text])

  return <span className="break-all">{els}</span>
}
