import { DndContext, DragEndEvent, UniqueIdentifier } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Cell,
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  OnChangeFn,
  Row,
  RowSelectionState,
  Table,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table'
import clsx from 'clsx'
import {
  Context,
  createContext,
  CSSProperties,
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import useContextOrThrow from '@/hooks/useContextOrThrow.ts'
import useSortableProps from '@/hooks/useSortableProps.ts'

interface GridContextValue<T> {
  table: Table<T>
  rows: T[]
  rowIds: UniqueIdentifier[]
  columns: ColumnDef<T>[]
  sortable?: boolean
  className?: {
    body?: string
    head?: string
    th?: string
    td?: string
    tr?: string
    tdLastRow?: string
  }
  noHeader?: boolean
  onSort?: SetState<T[]>
  onSortCb?: (items: T[]) => void
  spannedRows: {
    [col: string]: {
      [v: string]: {
        index: number
        span: number
      }
    }
  }
  fixed?: boolean
  expanded: ExpandedState
  setExpanded: SetState<ExpandedState>
  excludeRow?: (row: T) => boolean
  singleExpanded?: (row: Row<T>) => ReactNode
  //displayedCell: MutableRefObject<Record<string, number>>
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GridContext = createContext<GridContextValue<any> | null>(null)
GridContext.displayName = 'GridContext'

// eslint-disable-next-line react-refresh/only-export-components
export function useGrid() {
  return useContextOrThrow(GridContext)
}

function DndGridBody() {
  const { rows, onSort, onSortCb, rowIds } = useGrid()
  const sortableProps = useSortableProps()
  const handleDragEnd = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (over && active.id !== over?.id) {
        if (onSortCb) {
          const oldIndex = rowIds.indexOf(active.id)
          const newIndex = rowIds.indexOf(over.id)
          onSortCb(arrayMove(rows, oldIndex, newIndex))
        } else {
          onSort?.((prev) => {
            const oldIndex = rowIds.indexOf(active.id)
            const newIndex = rowIds.indexOf(over.id)
            return arrayMove(prev, oldIndex, newIndex) //this is just a splice util
          })
        }
      }
    },
    [rowIds, rows, onSortCb, onSort],
  )

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => rows.map(({ id }) => id),
    [rows],
  )

  return (
    <DndContext
      accessibility={{
        container: document.body,
      }}
      {...sortableProps}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
        <GridBody />
      </SortableContext>
    </DndContext>
  )
}

function SortableRow<T>({
  row,
  id,
  isLastRow,
}: {
  id: string
  row: Row<T>
  isLastRow: boolean
}) {
  const { className } = useGrid()
  const { isDragging, setNodeRef, transition, attributes, transform } =
    useSortable({
      id,
    })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <tr
      ref={setNodeRef}
      className={className?.tr}
      style={style}
      {...attributes}
    >
      <GridRow isLastRow={isLastRow} row={row} />
    </tr>
  )
}

function GridCell<T>({
  row,
  cell,
  isLastRow,
}: {
  row: Row<T>
  cell: Cell<T, unknown>
  isLastRow: boolean
}) {
  const { className, spannedRows } = useGrid()
  const colId = useMemo(() => cell.column.id, [cell])
  const meta = useMemo(
    () => cell.column.columnDef.meta as Record<string, unknown>,
    [cell],
  )
  const isRowSpanned = meta?.enableRowSpan === true
  const value = (row.getValue(colId) as string) || ''

  return (
    <td
      data-cell-id={cell.column.id}
      className={clsx(
        'px-4 h-12 border-e last:border-e-0 border-white/30',
        'border-b',
        className?.td,
        isLastRow ? className?.tdLastRow : '',
        meta?.className as string,
        isRowSpanned
          ? spannedRows[colId]?.[value] !== undefined &&
            row.index === spannedRows[colId]?.[value].index
            ? ''
            : 'hidden'
          : null,
      )}
      data-index={row.index}
      data-displayed-index={spannedRows[colId]?.[value].index}
      rowSpan={spannedRows[colId]?.[value].span}
      key={cell.id}
      style={{ width: cell.column.getSize() }}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  )
}

function GridRow<T>({ row, isLastRow }: { row: Row<T>; isLastRow: boolean }) {
  return row.getVisibleCells().map((cell: Cell<T, unknown>) => {
    return (
      <GridCell isLastRow={isLastRow} key={cell.id} row={row} cell={cell} />
    )
  })
}

function GridBody() {
  const { table, sortable, columns, singleExpanded, className } = useGrid()
  const tableRows = table.getRowModel().rows

  return tableRows.length > 0 ? (
    tableRows.map((row) => {
      const isLastRow = row.index === tableRows.length - 1

      return (
        <Fragment key={row.id}>
          {sortable ? (
            <SortableRow isLastRow={isLastRow} id={row.original.id} row={row} />
          ) : (
            <tr className={className?.tr}>
              <GridRow isLastRow={isLastRow} row={row} />
            </tr>
          )}
          {singleExpanded && row.getIsExpanded() && (
            <tr className={className?.tr}>
              <td
                className={clsx(
                  'border-b border-e last:border-e-0 border-white/30',
                  isLastRow ? className?.tdLastRow : '',
                )}
                colSpan={columns.length}
              >
                {singleExpanded(row)}
              </td>
            </tr>
          )}
        </Fragment>
      )
    })
  ) : (
    <tr className={className?.tr}>
      <td
        colSpan={columns.length}
        className={clsx(
          'px-4 h-12 border-b border-e last:border-e-0 border-white/30',
        )}
      >
        No record found
      </td>
    </tr>
  )
}

interface GridProviderProps<T>
  extends Pick<
    GridContextValue<T>,
    'columns' | 'className' | 'sortable' | 'onSort' | 'onSortCb' | 'noHeader'
  > {
  rows: T[]
  getRowId?: (row: T) => string
  pagination?: {
    perPage: number
    total: number
    totalPages: number
    current: number
  }
  fixed?: boolean
  getSubRows?: TableOptions<T>['getSubRows']
  getRowCanExpand?: TableOptions<T>['getRowCanExpand']
  onRowSelectionChange?: TableOptions<T>['onRowSelectionChange']
  state?: TableOptions<T>['state']
  defaultExpanded?: ExpandedState
  children?: ReactNode
  getFilteredRowModel?: TableOptions<T>['getFilteredRowModel']
  selectedRows?: RowSelectionState
  singleExpanded?: (row: Row<T>) => ReactNode
  onExpanded?: OnChangeFn<ExpandedState>
}

export default function GridProvider<T>({
  columns,
  rows,
  className,
  sortable,
  onSort,
  getRowId,
  fixed,
  getSubRows,
  getRowCanExpand,
  defaultExpanded,
  children,
  onRowSelectionChange,
  state,
  getFilteredRowModel,
  selectedRows,
  singleExpanded,
  onExpanded,
  onSortCb,
  noHeader,
}: GridProviderProps<T>) {
  const [expanded, setExpanded] = useState<ExpandedState>(defaultExpanded ?? {})
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    selectedRows || {},
  )

  const table = useReactTable<T>({
    columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    getSubRows,
    getRowCanExpand,
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      size: 50, //starting column size
      //minSize: 50, //enforced during column resizing
      //maxSize: 500, //enforced during column resizing
    },
    getRowId,
    getFilteredRowModel,
    onPaginationChange: () => undefined,
    //enableExpanding: true,
    state: {
      expanded,
      rowSelection,
      ...state,
    },
    onRowSelectionChange: onRowSelectionChange
      ? onRowSelectionChange
      : setRowSelection,
    onExpandedChange: (v) => {
      onExpanded?.(v)
      if (typeof v === 'function') {
        setExpanded(v)
      } else {
        if (Object.values(v).length > 0) {
          setExpanded(v)
        } else {
          setExpanded(defaultExpanded ?? {})
        }
      }
    },
  })

  const GContext: Context<GridContextValue<T> | null> = GridContext

  const spannedRows = useMemo(() => {
    const rows = table.getRowModel().rows
    const tempSpannedRows: Record<
      string,
      Record<
        string,
        {
          index: number
          span: number
        }
      >
    > = {}

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      const cells = row.getVisibleCells()
      for (let j = 0; j < cells.length; j++) {
        const cell = cells[j]
        const colId = cell.column.id
        const meta = cell.column.columnDef.meta as Record<string, unknown>
        const isRowSpanned = meta?.enableRowSpan === true

        if (isRowSpanned) {
          const value = row.getValue(colId) as string
          if (tempSpannedRows[colId]?.[value]) {
            tempSpannedRows[colId][value].span += 1
          } else {
            tempSpannedRows[colId] = {
              ...tempSpannedRows[colId],
              [value]: {
                span: 1,
                index: row.index,
              },
            }
          }
        }
      }
    }
    return tempSpannedRows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, table])

  //const displayedCell = useRef({})

  const rowIds = useMemo(() => {
    return sortable ? rows.map((r) => (r as { id: string })?.id) : []
  }, [sortable, rows])

  return (
    <GContext.Provider
      value={{
        table,
        columns,
        rows,
        rowIds,
        className,
        sortable,
        onSort,
        onSortCb,
        spannedRows,
        fixed,
        expanded,
        setExpanded,
        singleExpanded,
        noHeader,
        //displayedCell,
      }}
    >
      <table
        data-testid="grid-table"
        className={clsx(
          'w-full text-start',
          fixed ? 'table-fixed' : 'table-auto',
        )}
      >
        {!noHeader && (
          <thead
            data-testid="grid-thead"
            className={clsx(
              'bg-ed-blue border border-ed-blue',
              className?.head,
            )}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={clsx(
                      'uppercase text-body tracking-wide h-12 text-start px-4',
                      className?.th,
                    )}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody
          data-testid="grid-tbody"
          className={clsx('border-x border-white/30', className?.body)}
        >
          {sortable ? <DndGridBody /> : <GridBody />}
        </tbody>
      </table>
      {children}
    </GContext.Provider>
  )
}
