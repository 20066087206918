import { SquircleButton } from '@edclass/fe-ui'
import {
  Dialog,
  DialogBody,
  DialogProps,
  Typography,
} from '@material-tailwind/react'

import useAppContext from '@/hooks/useAppProvider.ts'

export interface StudentAlertModalProps {
  className?: string
}

export default function StudentAlertModal({
  className,
  ...props
}: StudentAlertModalProps & Omit<DialogProps, 'ref' | 'children'>) {
  const { sendSgMessage } = useAppContext()
  return (
    <Dialog {...props}>
      <DialogBody className={className}>
        <Typography variant="h5">Are you safe?</Typography>
        <div className="flex-c gap-8 p-8 justify-center">
          <SquircleButton
            className="text-2xl uppercase font-bold"
            color="green"
            onClick={() => {
              props.handler(false)
            }}
          >
            Yes
          </SquircleButton>
          <SquircleButton
            className="text-2xl uppercase font-bold"
            color="red"
            onClick={() => {
              sendSgMessage({
                msg: 'safeguarding',
                action: 'student-alert',
                uri: window.location.href,
              })
              props.handler(false)
            }}
          >
            No
          </SquircleButton>
        </div>
      </DialogBody>
    </Dialog>
  )
}
