import { Sym } from '@edclass/fe-ui'
import { Spinner } from '@material-tailwind/react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Avatar from '@/components/Avatar'
import { EDIcon } from '@/constants/constants.ts'
import { getUserDisplayName } from '@/helpers/user.ts'
import useStudent from '@/hooks/query/useStudent.ts'
import useAppContext from '@/hooks/useAppProvider.ts'

function PageImpl({ user }: { user: User }) {
  const { headerTitle } = useAppContext()
  const keys = Object.keys(user) as Array<keyof User>

  useEffect(() => {
    // test unbind all events
    /*
    document.addEventListener('keydown', (e) => {
      e.preventDefault()
      return false
    })
    document.addEventListener('keypress', (e) => {
      e.preventDefault()
      return false
    })
    document.addEventListener('keyup', (e) => {
      e.preventDefault()
      return false
    })
    window.addEventListener('keydown', (e) => {
      e.preventDefault()
      return false
    })
    window.addEventListener('keypress', (e) => {
      e.preventDefault()
      return false
    })
    window.addEventListener('keyup', (e) => {
      e.preventDefault()
      return false
    })
     */
  }, [])

  return (
    <div>
      <headerTitle.In>
        <Sym>{EDIcon.User}</Sym>
        <span>{getUserDisplayName(user)} Profile</span>
      </headerTitle.In>
      <div className="flex-c gap-8">
        <div className="relative cursor-pointer bg-blue-gray-500 w-[136px] h-[136px] rounded-full cc group/pic">
          <Avatar user={user} size={136} />
        </div>
        <div>
          {keys.map((k) => {
            return (
              <div>
                <div>{k}</div>
                <div>{user[k]?.toString()}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default function StudentProfile() {
  const { id } = useParams()
  const { data, isPending } = useStudent(id!)

  return isPending ? <Spinner /> : <PageImpl user={data as unknown as User} />
}
