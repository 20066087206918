import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { AdminChildrenPath } from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(() => import('@/pages/AdminPage/AdminMarkingPage/index.tsx'))

// eslint-disable-next-line react-refresh/only-export-components
const ByQuestion = lazy(
  () => import('@/pages/AdminPage/AdminMarkingPage/MarkingByQuestionPage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const ByTask = lazy(
  () => import('@/pages/AdminPage/AdminMarkingPage/MarkingTaskPage.tsx'),
)

export default {
  path: AdminChildrenPath.MARKING,
  element: (
    <Suspense fallback={<></>}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: 'by-question',
      element: <ByQuestion />,
    },
    {
      path: 'by-task',
      element: <ByTask />,
    },
  ],
}
