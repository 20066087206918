import { Sym } from '@edclass/fe-ui'
import { IconButton } from '@material-tailwind/react'
import { useRef, useState } from 'react'

import { FileDisplaySmall } from '@/components/FileManager/FileDisplay.tsx'
import FileManager from '@/components/FileManager/index.tsx'
import {
  FileManagerInputProps,
  FileManagerTabFlag,
} from '@/components/FileManager/types.ts'
import { getExtension, getThumbTypeFromStr } from '@/helpers/fs.ts'
import { getFsUrl } from '@/services/fs.ts'

export default function FileManagerInput({
  onClick,
  selectedFiles,
  onUpload,
  onSelect,
  btnIcon = 'folder_open',
  multiple,
  label = 'Select file',
  accept,
  tabFlags = FileManagerTabFlag.All,
  aiPrompt,
  onPromptChange,
  aiImageName,
  contentType,
  preview,
}: FileManagerInputProps) {
  const [open, setOpen] = useState(false)
  const [, setFilesToUpload] = useState<File[]>([])
  const btnRef = useRef<HTMLButtonElement>(null)

  return (
    <div className="fm-panel relative w-full min-h-10 min-w-0 flex items-center border rounded-md border-blue-gray-200 py-1.5 px-3">
      {selectedFiles.length > 0 ? null : (
        <div
          onClick={() => {
            if (!open) {
              btnRef.current?.click()
            }
          }}
          className="flex-shrink-0 flex items-center text-blue-gray-500"
        >
          {label}
        </div>
      )}
      <div
        onClick={() => {
          if (!open) {
            btnRef.current?.click()
          }
        }}
        className="w-full truncate flex flex-col gap-2"
      >
        {selectedFiles.map((selectedFile, i) => {
          let prt = null
          if (preview && typeof selectedFile === 'string' && selectedFile) {
            const type = getThumbTypeFromStr(
              getExtension({
                key: selectedFile?.toLowerCase(),
              } as unknown as FsItem),
            )
            switch (type) {
              case 'image':
                prt = (
                  <div className="w-[50%]">
                    <img src={getFsUrl(selectedFile)} />
                  </div>
                )
                break
              case 'audio':
                prt = (
                  <div className="w-[50%]">
                    <audio src={getFsUrl(selectedFile)} />
                  </div>
                )
                break
              case 'video':
                prt = (
                  <div className="w-[50%]">
                    <video src={getFsUrl(selectedFile)} />
                  </div>
                )
                break
            }
          }

          return (
            <div key={i} className="flex-c-2">
              {prt}
              <FileDisplaySmall
                onRemove={() => {
                  onSelect(selectedFiles.filter((v) => v !== selectedFile))
                }}
                key={i}
                path={selectedFile}
              />
            </div>
          )
        })}
      </div>
      <IconButton
        size="sm"
        ref={btnRef}
        className="flex-shrink-0 h-[26px] w-[26px] rounded [&>span]:cc"
        onClick={(e) => {
          setOpen(!open)
          onClick?.(e)
        }}
      >
        <Sym className="!text-[20px]">{btnIcon}</Sym>
      </IconButton>
      <FileManager
        contentType={contentType}
        aiImageName={aiImageName}
        aiPrompt={aiPrompt}
        onPromptChange={onPromptChange}
        tabFlags={tabFlags}
        multiple={multiple}
        modal={{
          open,
          handler: () => setOpen(!open),
        }}
        accept={accept}
        onChange={(item) => {
          onSelect(item.map((i) => i.key))
        }}
        onUpload={(f) => {
          setFilesToUpload(f)
          return onUpload(f)
        }}
      />
    </div>
  )
}
