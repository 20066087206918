import './App.css'

import clsx from 'clsx'
import { Suspense, useMemo } from 'react'
import { Toaster } from 'react-hot-toast'
import { matchPath, Outlet, useLocation } from 'react-router-dom'

import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import UploadPanel from '@/components/UploadPanel'
import useAppContext from '@/hooks/useAppProvider.ts'
import { sidebarHiddenPaths } from '@/services/app.ts'

function App() {
  const { sidebarCollapsed } = useAppContext()

  const { pathname } = useLocation()
  const matches = useMemo(() => {
    return Array.from(sidebarHiddenPaths).some((pathToMatch) => {
      return matchPath(pathToMatch, pathname)
    })
  }, [pathname])

  /*
  if (
    sgReadyState === ReadyState.CONNECTING ||
    eventReadyState === ReadyState.CONNECTING
  ) {
    return <AppSkeleton />
  }*/

  return (
    <div id="main">
      {!matches && <Sidebar />}
      <div
        id="page"
        className={clsx(sidebarCollapsed && 'sb-collapsed', matches && 'no-sb')}
      >
        <Header />
        <div id="page-content">
          <Suspense fallback={<div>loading app route</div>}>
            <Outlet />
          </Suspense>
        </div>
        <Toaster containerClassName="!z-[1000000] transition-all duration-500" />
      </div>
      <UploadPanel />
    </div>
  )
}

export default App
