import { QueryClient } from '@tanstack/react-query'

export function addQueryData<T>(
  res: T,
  queryClient: QueryClient,
  queryKey: unknown[],
  noInvalidate: boolean = false,
) {
  queryClient.setQueryData(queryKey, (old: PaginatedItems<T>) => {
    const next = [res, ...(old?.data ?? [])]
    if (old?.page?.perPage && next.length > old?.page?.perPage) {
      next.pop()
    }
    return {
      ...old,
      data: next,
    }
  })
  if (!noInvalidate) {
    queryClient.invalidateQueries({
      queryKey: queryKey,
      exact: false,
    })
  }
}

export function updateQueryData<T extends WithId>(
  res: T,
  queryClient: QueryClient,
  queryKey: unknown[],
) {
  queryClient.setQueryData(queryKey, (old: PaginatedItems<T>) => {
    const copy = [...(old?.data ?? [])]
    const idx = copy.findIndex((c) => c.id === res.id)
    copy[idx] = res
    return {
      ...old,
      data: copy,
    }
  })
  queryClient.invalidateQueries({
    queryKey: queryKey,
    exact: false,
  })
}

export function removeQueryData<T extends WithId>(
  res: T,
  queryClient: QueryClient,
  queryKey: unknown[],
  noInvalidate: boolean = false,
  getId?: (item: Partial<T>) => string,
) {
  queryClient.setQueryData(queryKey, (old: PaginatedItems<T>) => {
    const copy = [...(old?.data ?? [])].filter((c) => {
      const lhs = getId ? getId(c) : c.id
      const rhs = getId ? getId(res) : res.id
      return lhs !== rhs
    })
    return {
      ...old,
      data: copy,
    }
  })
  if (!noInvalidate) {
    queryClient.invalidateQueries({
      queryKey: queryKey,
      exact: false,
    })
  }
}

export function updateQueryDataPartial<T extends WithId>(
  res: Partial<T>,
  queryClient: QueryClient,
  queryKey: unknown[],
  noInvalidate: boolean = false,
  getId?: (item: Partial<T>) => string,
) {
  queryClient.setQueryData(queryKey, (old: PaginatedItems<T>) => {
    const copy = [...(old?.data ?? [])]
    const idx = copy.findIndex((c) => {
      const lhs = getId ? getId(c) : c.id
      const rhs = getId ? getId(res) : res.id
      return lhs === rhs
    })
    copy[idx] = {
      ...copy[idx],
      ...res,
    }
    return {
      ...old,
      data: copy,
    }
  })
  if (!noInvalidate) {
    queryClient.invalidateQueries({
      queryKey: queryKey,
      exact: false,
    })
  }
}
