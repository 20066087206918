import { Http } from '@/helpers/http.ts'
import { BaseService } from '@/services/base.ts'
import { Params } from '@/services/params.ts'

const BASE_URL: string = import.meta.env.VITE_SG_API_URL
export const API_VERSION = 'v1'

class SgService extends BaseService {
  private static instance: SgService
  protected _client: Http

  constructor() {
    super()
    this._client = new Http({
      baseUrl: `${BASE_URL}`,
    })
  }

  public static getInstance() {
    if (!SgService.instance) {
      SgService.instance = new SgService()
    }
    return SgService.instance
  }

  sendStudentAlert() {
    return this.send(this._client.post(`alerts/students`))
  }

  sendAlert(body: Record<string, unknown>) {
    return this.send(this._client.post(`alerts`, body))
  }

  listStudent(params?: Params) {
    return this.sendParams<
      PaginatedItems<{
        student: Student
        room: IvsRoomSummary
      }>
    >(`data/students`, params)
  }

  listEDTeachStudentOnline(params?: Params) {
    return this.sendParams<
      PaginatedItems<{
        student: Student
        room: IvsRoomSummary
      }>
    >(`data/students/edteach/online/search`, params)
  }

  listStudentNotes(id: string, params?: Params) {
    return this.sendParams<PaginatedItems<StudentNote>>(
      `students/notes/${id}`,
      params,
    )
  }
  addStudentNote(body: StudentNoteReqBody) {
    return this.send<StudentNote>(this._client.post(`students/notes`, body))
  }
  updateStudentNote(body: StudentNoteReqBody) {
    return this.send<StudentNote>(
      this._client.post(`students/notes/${body._id?.$oid}`, body),
    )
  }
  deleteStudentNote(id: Oid) {
    return this.send<void>(this._client.delete(`students/notes/${id.$oid}`))
  }
  listTag(params?: Params) {
    return this.sendParams<PaginatedItems<Tag>>(`tags`, params)
  }
  addTag(body: TagReqBody) {
    return this.send<StudentNote>(this._client.post(`tags`, body))
  }
  updateTag(body: TagReqBody) {
    return this.send<StudentNote>(
      this._client.post(`tags/${body._id?.$oid}`, body),
    )
  }
  deleteTag(id: Oid) {
    return this.send<void>(this._client.delete(`tags/${id.$oid}`))
  }
  listEDIncludeStudentOnline() {
    return this.send<
      PaginatedItems<{
        student: Student
        room: IvsRoomSummary
      }>
    >(this._client.get(`data/students/edinclude/online/search`))
  }

  createRoom() {
    return this.send<IvsJoinResponse>(this._client.post(`rooms/create`))
  }

  joinRoomByOwnerId(ownerId: string) {
    return this.send<IvsJoinResponse>(
      this._client.post(`rooms/owner/${ownerId}/join`),
    )
  }

  joinRoomByRoomId(roomId: string) {
    return this.send<IvsJoinResponse>(this._client.post(`rooms/${roomId}/join`))
  }

  getRoom(roomId: string) {
    return this.send<IvsJoinResponse>(this._client.get(`rooms/${roomId}`))
  }

  createChatToken(roomId: string) {
    return this.send(this._client.get(`rooms/${roomId}/chat/token`))
  }

  listChat(chatArn: string, nextToken?: string) {
    return this.sendParams<{
      events: Array<{
        timestamp: number
        message: string
      }>
      nextForwardToken?: string
      nextBackwardToken?: string
    }>(
      `chats/${chatArn}`,
      nextToken
        ? Params.fromObject({
            nextToken: encodeURIComponent(nextToken),
          })
        : undefined,
    )
  }
  listChatToday(chatArn: string, nextToken?: string) {
    return this.sendParams<{
      events: Array<{
        timestamp: number
        message: string
      }>
      nextForwardToken?: string
      nextBackwardToken?: string
    }>(
      `chats/${chatArn}/today`,
      nextToken
        ? Params.fromObject({
            nextToken: encodeURIComponent(nextToken),
          })
        : undefined,
    )
  }
}

export function getSgService() {
  return SgService.getInstance()
}
